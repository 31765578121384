import React from 'react'
import { Link } from 'react-router-dom'
import Faq from '../../components/Faq'
import Meta from '../../components/Meta'
import SocietyRegistrationImg from '../../assets/images/Society-Registration.png'
import societyregistrationinbangladesh from '../../assets/images/society-registration-in-bangladesh.png'
import SocietyRegistrationTypes from '../../assets/images/Society-Registration-Types.png'
import societyRegistration_og_img from "../../assets/og-img/Society Registration.png"
import Marquee from 'react-fast-marquee';
import { startupRelatedArticales } from '../../data/relatedArticale'

function SocietyRegistration() {

   const faqItems = [
      {
        title: 'What is the Society Act 1860 in Bangladesh?',
        content: <strong>An Act for the Registration of Literary, Scientific and Charitable Societies.</strong>
      },
      {
        title: 'How can I register a non profit organization in Bangladesh?',
        content: <strong>Registering under the Department of Social Services (DSS)Submitting an
        application (using “Form-B”)</strong>
      },
      {
        title: 'What are the classification of NGOs in Bangladesh?',
        content: <strong>Based on the nature of activities, NGOs in Bangladesh can be broadly
        categorized as Microcredit NGOs (NGO-MFI) and Development NGOs (NGO-
        Development)</strong>
      }
    ];

  return (
    <>
      <Meta title="Society Registration in Bangladesh | NGO Registration"
            description="Society Registration in Bangladesh is important for social organization how wants to work for society. NGO Registration is similar organizational structure"
            image={societyRegistration_og_img}
            keywords="Society Registration, Society Registration in Bangladesh, NGO Registration in Bangladesh"
      />

      <div className="container-fluid hero-header bg-light py-5 ">
          <div className="container py-5">
              <div className="row g-5 align-items-center">
                  <div className="col-lg-6">
                      <h1 className="display-4 mb-3 animated slideInDown">Society Registration</h1>
                      <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb mb-0">
                           <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                           <li className="breadcrumb-item"><Link to="#">Startup</Link></li>
                           <li className="breadcrumb-item active" aria-current="page">Society Registration</li>
                        </ol>
                     </nav>
                 </div>
                 <div className="col-lg-6 animated fadeIn">
                     <img className="img-fluid animated pulse infinite" style={{animationDuration: '3s'}} src={SocietyRegistrationImg} alt='HeaderBanner' />
                 </div>
             </div>
         </div>
      </div>

      <div className="container-xxl py-5">
         <div className="container">
            <p>
               Societies Registration Act, 1860, A Society Registration need any seven or more persons 
               associated for any literary, scientific or charitable purpose or for any such purpose as 
               is described in section 20 of this Act, may, by subscribing their names to a memorandum of 
               association and filing the same with the Registrar of Joint stock Companies form themselves 
               into a society under this Act. Society registration in Bangladesh under Registrar of Joint 
               Stock Companies and Firms under the Ministry of Commerce.
            </p>
            <p className="color-text fs-5 mb-4">
                <b>MEMORANDUM OF ASSOCIATION</b>
            </p>
            <p>
               The memorandum of association shall contain the following things (that is to say)-
            </p>
            <p>
            <div className="d-flex align-items-center mb-4">
              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                    <b>The name of the society:</b> The names, addresses and occupations of the 
                       governors, council, directors, committee or other governing body to whom, by 
                       the rules of the society, the management of its affairs is entrusted.
                </span>
            </div>

            <div className="d-flex align-items-center mb-4">
              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                     <b>The objects of the society:</b> A copy of the rules and regulations of the 
                        society, certified to be a correct copy by not less than three of the members 
                        of the governing body shall be filed with the memorandum of association.
                </span>
            </div>
            </p>
            <p className="color-text fs-5 mb-4">
                <b>Society Registration</b>
            </p>

            <p>
                Upon the filing of the memorandum and certified copy of the rules and regulations 
                of the society under section 2, the registrar shall certify under his hand that 
                the society is registered under this Act.
            </p>
         </div>
      </div>

      <div className="container-xxl py-5">
         <div className="container">
             <div className="row g-5 align-items-center">
                <div className="col-lg-6  "  >
                    <img className="img-fluid" src={societyregistrationinbangladesh} alt='Company-Registration-Process' />
                </div>
                <div className="col-lg-6  "  >
                    <div className="h-100">
                         <p className="color-text fs-5 mb-4">
                            FEES TO BE PAID TO THE REGISTRAR of Society Registration
                         </p>
                         <p>
                           Shall be paid to the registrar for registration of a society and for matters 
                           mentioned in the Schedule to this Act, fees specified in that Schedule or such 
                           smaller fees as the Government may direct.
                         </p>
                         <p className="color-text fs-5 mb-4">STEP 1: NAME CLEARANCE</p>
                         <p>
                            Firstly, To set up a society (foundation) your step should obtain a name 
                            clearance for the proposed company name. You need to visit www.roc.gov.bd 
                            and create a username. Then, you will be able to apply for name clearance. 
                            After, you made the application for name clearance, you will receive a bank 
                            payment slip you have to pay BDT 1,150 to the designated bank. After making the 
                            payment, you will have to login into your account on the RJSC website and then 
                            you will get the name clearance. An approved name will reserve for 6 months from 
                            the date of clearance. You can extend the name by filling an extension request 
                            just before the expiry date.
                         </p>

                         <p className="color-text fs-5 mb-4">STEP 2: Society Registration</p>

                         <p >
                            Secondly, submit all the required information in the RJSC website.  Also, it is 
                            required to upload Form IX  and Subscriber Page. After finishing all the process,
                             you will receive a bank payment slip for paying the registration fees along with
                              stamp duty. <br></br>
                            After making the payment in the bank and you are done. Now you have to follow 
                            up the RJSC for obtaining the incorporation certificate. RJSC officials will 
                            check the documents and information. If they are satisfied, they will issue the 
                            digitally signed I) Certificate of Incorporation; ii) MOA and AOA; and iii) 
                            Form XII. These documents will be mailed to your email address associated with 
                            your RJSC account <br></br>
                            There are cases when the incorporation procedure can get delayed if the 
                            shareholders or directors are of certain nationalities. Although, this is a rare 
                            case. In such cases, the authorities might ask for some additional information.
                         </p>

                         <p className="color-text fs-5 mb-4">POST-REGISTRATION FORMALITIES for Society Registration</p>
                         <p>
                            Finally, you also need some documents that are issued by Registrar of Joint 
                            Stock Companies and Firms (RJSC).
                         </p>

                         <p className="color-text fs-5 mb-4">DOCUMENTS ISSUED BY RJSC:</p>
                         <div className="d-flex align-items-center mb-4">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                               Certificate of Registration of Societies: RJSC will issue a Certificate of 
                               Registration of the Societies. The certificate will contain the society 
                               registration number and name of the society the date of incorporation.
                            </span> 
                         </div>
                         <p>
                             Some of the other items you will almost need to registration of your Bangladeshi 
                             society. Like,
                         </p>

                         <div className="d-flex align-items-center mb-4">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Register for shareholders, shares, directors etc.
                            </span> 
                         </div>

                         <div className="d-flex align-items-center mb-4">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                society common seal for the society
                            </span> 
                         </div>

                         <div className="d-flex align-items-center mb-4">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                A rubber stamp for the society
                            </span> 
                         </div>
                        
                    </div>
                </div>
            </div>
        </div>
      </div>

      <div className="container-xxl py-0">
         <div className="container">
             <div className="row g-5 align-items-center">
                
                <div className="col-lg-6  "  >
                    <div className="h-100">
                         <p className="color-text fs-5 mb-4">
                            APPLYING FOR TRADE LICENSE, TAX IDENTIFICATION NUMBER AND OTHER LICENSES
                        </p>
                         <p>
                            After the incorporation, you should manage a commercial space or rent some space 
                            in any commercial area.<br></br>
                            Then you need to apply for Trade License, Tax Identification Number. 
                            Depending on your society activities, you may need to obtain more business 
                            licenses.
                         </p>

                         <p className="color-text fs-5 mb-4">ANNUAL LIST OF MANAGING BODY TO BE FILED</p>

                         <p >
                            Once in every year, on or before the fourteenth day succeeding the day on which, according to 
                            the rules of the society, the annual general meeting of the society is held, or, if the rules 
                            do not provide for an annual general meeting, in the month of January, a list shall be filed 
                            with the Registrar of Joint-stock Companies of the names, addresses and occupations of the 
                            governors, council, directors, committee or other governing body then entrusted with the 
                            management of the affairs of the society.
                         </p>

                         <p className="color-text fs-5 mb-4">REGISTRATION OF SOCIETIES</p>
                         <p>
                            Any society established for a literary, scientific or charitable purpose, and 
                            registered under Societies Registration Act, 1860, or any such society 
                            established and constituted previously to the passing of this Act but not 
                            registered under the said Act XLIII of 1860, may at any time hereafter be 
                            registered as a society under this Act; subject to the proviso that no such 
                            company or society shall be registered under this Act unless an assent to its 
                            being so registered has been given by three-fifths of the members present 
                            personally, or by proxy, at some general meeting convened for that purpose by 
                            the governing body.
                         </p>
                         <p>
                            In the case of a company or society registered under Act XLIII of 1860, the 
                            directors shall be deemed to be such governing body. In the case of a society 
                            not so registered if no such body shall have been constituted on the 
                            establishment of the society, it shall be competent for the members thereof, 
                            upon due notice, to create for itself a governing body to act for the society 
                            thenceforth.
                         </p>

                         <p>
                            As Bangladesh is becoming one of the most promising outbound markets in the 
                            world. Due to swift growth of economy in this country, society formation 
                            procedure is broadly asking question to the new entrepreneurs. All the questions 
                            are answered above how you should register your society.
                         </p>
                    </div>
                </div>

                <div className="col-lg-6  "  >
                    <img className="img-fluid" src={SocietyRegistrationTypes} alt='Company-Registration-Process' />
                </div>
            </div>
        </div>
      </div>

      <div className='container-xxl center-background1'>
                <h2> Related Articles</h2>
            </div>
            
            <div className='container-xxl center-background1'>
            <Marquee className="marquee-container">
               {
                startupRelatedArticales
               }
            </Marquee>
            </div>

      <div className="container py-5 my-0">
        <h2 className="color-text text-center mb-4">Society Registration FAQ</h2>
        <Faq items={faqItems} />
      </div>

    </>
  )
}

export default SocietyRegistration