import React from "react";
import headquartersBannerImg from "../assets/images/headquatarsBanner.jpg";

export default function Headquarters() {
  return (
    <>
      <div className="container-xxl " style={{ padding: "7% 7% 0 7%" }}>
        <div>
          <h1
            style={{ marginBottom: "20px", color: "black" }}
            className="display-6"
          >
            Headquarters
          </h1>
          <p style={{ marginTop: "20px" }}>
            Dubai: your preferred global arena for business.
          </p>
        </div>
      </div>
      <div style={{ marginTop: "40px" }}>
        <img
          src={headquartersBannerImg}
          alt="home office banner"
          width={"100%"}
          style={{ borderBottomRightRadius: "137px" }}
        />
      </div>

      <div
        style={{
          background: "#ececed",
          padding: "30px 0",
          marginBottom: "40px",
          width: "90%",
          float: "inline-end",
          borderBottomLeftRadius: "85px",
        }}
      >
        <div style={{ width: "80%", margin: "auto" }}>
          <p style={{ width: "80%", lineHeight: "28px" }}>
            Dubai is a hyper-connected business gateway offering unrivalled
            access to fast-growing markets across the world. Its dynamic
            business ecosystem is driven by world-class infrastructure,
            supportive policies and strong governance. This is why Fortune 500
            firms, FTSE100 giants and innovative start-ups have chosen Dubai as
            a launchpad for expansion and growth.
            <br />
            <br />
            Setting up a corporate office or regional or global headquarters in
            Dubai is easy and cost-effective. Numerous options are available
            when it comes to licences, visas and workspaces. Specific programmes
            and assets are also in place to help start-ups grow and existing
            firms flourish.
            <br />
            <br />
            Companies can also benefit from a highly qualified workforce
            comprising people from 200+ nationalities. Dubai also consistently
            ranks as one of the safest and best cities for expats to live and
            work. All these factors combine to make this city an ideal choice
            for companies of all sizes to set up and scale operations.
          </p>
        </div>
      </div>

      <div className="row" style={{ width: "80%", margin: "auto" }}>
        <div className="col-6">
          <div
            style={{
              width: "90%",
              background: "#005f66",
              color: "white",
              padding: "40px 35px",
              borderRadius: "15px",
            }}
          >
            <h2>70%</h2>
            <p style={{ marginBottom: "0px" }}>
              of Fortune 500 firms have regional headquarters in Dubai
            </p>
          </div>
        </div>
        <div className="col-6">
          <div
            style={{
              width: "90%",
              background: "#005f66",
              color: "white",
              padding: "40px 35px",
              borderRadius: "15px",
            }}
          >
            <h2>90%</h2>
            <p style={{ marginBottom: "0px" }}>
              of the UAE’s scale-ups are headquartered in Dubai
            </p>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
          background: "#d0e4eb",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>Sustained growth through pro-business initiatives </h3>
          <p>
            Strategic efforts are underway to position Dubai among the world’s
            top five centres for business, logistics and finance by 2033. The
            D33 agenda has outlined 100 transformational projects to double
            Dubai’s economy and position it among the world’s top three cities
            by economic value.
            <br />
            <br />
            Programmes like Operation 300bn and the Dubai Industrial Strategy
            spearhead growth for manufacturing and industrial firms. Meanwhile,
            Dubai International Financial Centre, one of the world’s top
            financial hubs, offers a strong ecosystem for private equity and
            venture capital.
            <br />
            <br />
            SMEs and corporates can enjoy competitive advantages via 20+
            business-first free zones – including Jebel Ali Free Zone Authority
            (JAFZA), Dubai Multi Commodities Centre (DMCC) and Dubai World
            Central (DWC). These include 100% foreign ownership, lower tax
            policies, full reparations of profits, cost-effective set-up,
            renewal arrangements and simplified foreign recruitment processes.
          </p>
        </div>
      </div>
      <div
        style={{
          width: "80%",
          lineHeight: "28px",
          margin: "30px auto",
          color: "#505050",
        }}
      >
        <h3>Seamless connectivity and world-class logistics network </h3>
        <p>
          Dubai’s strategic location empowers companies with direct access to
          emerging markets, thanks to a superior logistics and supply chain
          network. The World Bank’s Logistics Performance Index scores Dubai 4.1
          out of 5, highlighting its exceptional trade and transport
          infrastructure.
          <br />
          <br />
          Jebel Ali Port, among the largest worldwide, is seamlessly connected
          to Al Maktoum Airport via the custom-bonded Dubai Logistics Corridor.
          Dubai Ports World (DP World) – ranked third in global container
          terminal operations – facilitates the flow of worldwide commerce to 69
          countries. Emirates and FlyDubai, the city’s two homegrown airlines,
          enable connections to more than 250 global destinations.
        </p>
      </div>
    </>
  );
}
