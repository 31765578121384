import React from 'react'
import { Link } from 'react-router-dom'
import Faq from '../../components/Faq';
import Meta from '../../components/Meta';
import HeaderBannerImg from '../../assets/images/HeaderBanner.png'
import CompanyRegistrationImg from '../../assets/images/Company-Registration-Process.png'
import RJSCReturnFillingImg from '../../assets/images/RJSC-Return-Filling-process-1.png'
import company_Formation_og_img from "../../assets/og-img/company-formation.png"
import Marquee from 'react-fast-marquee';
import { startupRelatedArticales } from '../../data/relatedArticale';
function CompanyFormation() {
  const faqItems = [
    {
      title: 'How to incorporate a company in Bangladesh?',
      content: <strong>This guide will provide you a detailed overview of company registration requirements,
      procedure, and timeline for registering a private limited company in Bangladesh.</strong>
    },
    {
      title: 'How the company is formed?',
      content: <strong>A complex activity involving completion of legal formalities and procedures.</strong>
    },
    {
      title: 'What is the cost of company formation in Bangladesh?',
      content: <strong>A Government organization, The Registrar of Joint Stock Companies and Firms (RJSC),
      permits the company&#39;s registration.</strong>
    },
    
  ];
  return (
    <>
       <Meta title="Company Formation in Bangladesh | Company Registration"
            description="Company Formation or Registration in Bangladesh. A private limited company in Bangladesh has a separate legal entity"
            image={company_Formation_og_img}
            keywords="Company Formation, Company Registration, Company Registration Bangladesh, private limited company"
       />
       
             

      <div className="container-fluid hero-header bg-light py-5 ">
        <div className="container py-5">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <h1 className="display-4 mb-3 animated slideInDown">Company Formation</h1>
              <nav aria-label="breadcrumb animated slideInDown">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#">Startup</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">Company Formation</li>
                </ol>
              </nav>
            </div>
            <div className="col-lg-6 animated fadeIn">
              <img className="img-fluid animated pulse infinite" style={{ animationDuration: '3s' }} src={HeaderBannerImg} alt='HeaderBanner' />
            </div>
          </div>
        </div>
      </div>


      <div className="container-xxl py-5">
        <div className="container">
          <p>The Company Formation or company registration relocation of the existing company of Bangladesh and also note that most of the Bangladeshi companies are registered as private limited companies (commonly known as private limited companies). A private limited company in Bangladesh has a separate legal entity and shareholders are not liable for the company’s debts beyond the amount of share capital they have contributed. According to the Companies Act 1994, any person (foreign or local) can company formation or register a company in Bangladesh who is above 18 years old.</p>
        </div>
      </div>


      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6  "  >
              <img className="img-fluid" src={CompanyRegistrationImg} alt='Company-Registration-Process' />
            </div>
            <div className="col-lg-6  "  >
              <div className="h-100">
                <h1 className="color-text display-6">Company Formation</h1>
                <p className="color-text fs-5 mb-4">KEY FACTS ABOUT COMPANY FORMATION</p>
                <p><b>Company Name:</b> Firstly, the name must be approved (cleared) before company formation
                  or incorporation of the company in Bangladesh.
                </p>
                <p><b>Directors:</b> Secondly, the Minimum two directors are mandatory. Directors can be either local or foreign.
                  Directors must be at least 18 years of age and must not be bankrupt or convicted for any malpractice in
                  the past. The law requires that a director must own qualification shares stated in the Articles
                  of Association. A shareholder which is not a natural person (i.e. a company) can select nominee director.
                </p>

                <p><b>Shareholders:</b> Thirdly A private limited company in Bangladesh can have a minimum of 2 and a
                  maximum of 50 shareholders. A director and shareholder can be the same or a different person.
                  The shareholder can be a person or another legal entity such as another company.
                  100% local or foreign shareholding is allowed in most sectors. New shares can be issued or
                  existing shares can be transferred to another person any time after the Bangladeshi company
                  has gone through the incorporation process.
                </p>

                <p><b>Authorized Capital:</b> You must state the authorized capital in the Memorandum of Association
                  and Articles of Association. It is the maximum amount of share capital that the company is
                  authorized to issue (allocate) to shareholders. Part of the authorized capital can remain issued.
                  There is no minimum or maximum limit for authorized capital in Bangladesh.
                </p>

                <p className="mb-4"><b>Paid-up Capital:</b> Paid-up capital (also known as share capital) can be
                  increased any time after the incorporation of the company.
                </p>

                <p className="mb-4"><b>Registered Address:</b> In order to register a company in Bangladesh, you
                  must provide a local address as the registered address of the company. The registered address
                  must be a physical address (can be either a residential or commercial address). Memorandum and
                  Articles of Association. The company to be incorporated must prepare a memorandum of
                  association (MOA) and articles of association (AOA).
                </p>
                <div className="d-flex align-items-center mb-2">
                  <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                  <span>Address must be in commercial space</span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                  <span>Company can use factory address as a register address.</span>
                </div>
                <div className="d-flex align-items-center mb-4">
                  <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                  <span>Also company can use co-space address.</span>
                </div>
                <a className="btn btn-primary py-3 px-4" href>Read More</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <div className="h-100">
            <p className="color-text fs-5 mb-4"><b>CONSIDERATIONS FOR FOREIGNER FOR COMPANY FORMATION</b></p>
            <p><b>Foreigners wishing to open a company in Bangladesh must take into consideration the following points:</b></p>
            <p>

              <div className="d-flex align-items-center mb-4">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                  You must open a bank account in the name of the proposed company with the name clearance obtained from the
                  Registrar of Joint Stock Companies and Firms (RJSC) i.e. the registrar of companies and bring in the
                  initial paid-up capital. This is mandatory for company incorporation in Bangladesh.
                </span>
              </div>
              <div className="d-flex align-items-center mb-4">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                  All company formation or company incorporation formalities can be handled without you having to visit
                  Bangladesh. The only exception may be opening a bank account, depending upon the bank you choose.
                </span>
              </div>
              <div className="d-flex align-items-center mb-4">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                  All the directors and shareholders can be foreigners.
                </span>
              </div>
              <div className="d-flex align-items-center mb-4">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                  You must open a bank account in the name of the proposed company with the name clearance obtained from the
                  Registrar of Joint Stock Companies and Firms (RJSC) i.e. the registrar of companies and bring in the
                  initial paid-up capital. This is mandatory for company incorporation in Bangladesh.
                </span>
              </div>
              <div className="d-flex align-items-center mb-4">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                  You must open a bank account in the name of the proposed company with the name clearance obtained from the
                  Registrar of Joint Stock Companies and Firms (RJSC) i.e. the registrar of companies and bring in the
                  initial paid-up capital. This is mandatory for company incorporation in Bangladesh.
                </span>
              </div>

            </p>

            <p className="color-text fs-5 mb-4"><b>REQUIRED DOCUMENTS FOR COMPANY FORMATION</b></p>
            <p>However, For the purpose of Company Formation or company incorporation in Bangladesh, the following information is required by the company registrar:</p>
            <p>
              <div className="d-flex align-items-center mb-4">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                  Company Name: A name clearance must be obtained.
                </span>
              </div>

              <div className="d-flex align-items-center mb-4">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                  Memorandum of Association and Articles of Association: RJSC requires that the object clause in the MOA to be within 400 words and 7 clauses.
                </span>
              </div>

              <div className="d-flex align-items-center mb-4">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                  Shareholders Particulars: (National ID if the shareholder is a Bangladeshi)
                </span>
              </div>

              <div className="d-flex align-items-center mb-4">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                  Directors Particulars: (Including Tax Identification Number)
                </span>
              </div>

              <div className="d-flex align-items-center mb-4">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                  Registered Address:
                </span>
              </div>

              <div className="d-flex align-items-center mb-4">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                  Singed Form IX and Subscriber Page: Scanned copy in pdf will be required.
                </span>
              </div>

              <div className="d-flex align-items-center mb-4">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                  For foreigners: Copy of passport of shareholder and director.*
                </span>
              </div>
            </p>

            <p className="color-text fs-5 mb-4"><b>COMPANY REGISTRATION PROCEDURE</b></p>
            <p>
              Company registration or company formation procedure in Bangladesh is partially computerized and online-based. The step-by-step setup procedure for a Company registration:
            </p>
            <p>
              <div className="d-flex align-items-center mb-4">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                  Name Clearance: Bank account opening also bringing the paid-up capital and finally
                </span>
              </div>
              <div className="d-flex align-items-center mb-4">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                  Company Registrations.
                </span>
              </div>
            </p>
            <p>
              <b>
                improve your chances of quick name approval and make sure the name:
              </b>
            </p>
            <p>
              <div className="d-flex align-items-center mb-4">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                  Is not identical or too similar to any existing local company names
                </span>
              </div>
              <div className="d-flex align-items-center mb-4">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                  Does not match with any trademarks
                </span>
              </div>
              <div className="d-flex align-items-center mb-4">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                  Is not obscene or vulgar
                </span>
              </div>
              <div className="d-flex align-items-center mb-4">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                  Is not already reserved
                </span>
              </div>
            </p>
            <p>An approved name will be reserved for 1 month from the date of approval. You can extend the name by filing an extension request just before the expiry date.</p>
          </div>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">

            <div className="col-lg-6  "  >
              <div className="h-100">
                <p className="color-text fs-5 mb-4">STEP 1: NAME CLEARANCE</p>
                <p>
                  Firstly, To set up a company your step should obtain a name clearance for the proposed
                  company name. You need to visit www.roc.gov.bd and create a username. Then, you will be
                  able to apply for name clearance. After you made the application for name clearance,
                  you will receive a bank payment slip you have to pay BDT 575 to the designated bank. After
                  making the payment, you will have to log in to your account on the RJSC website, and then
                  you will get the name clearance. An approved name will be reserved for 1 month from the
                  date of clearance. You can extend the name by filing an extension request just before
                  the expiry date.
                </p>
                <p className="color-text fs-5 mb-4">STEP 2: BANK ACCOUNT OPENING AND BRINGING IN THE PAID UP
                  CAPITAL
                </p>
                <p>
                  Secondly, this step is only applicable if the proposed company has foreign shareholders. You
                  have to open a bank account in the proposed company name with any scheduled bank in Bangladesh.
                  After opening the account, you will have to remit money equal to the shares to be owned by
                  the foreign shareholder from outside Bangladesh in the account. The Bank will issue an
                  Encashment Certificate which is required by RJSC for incorporation.
                </p>
                <p className="color-text fs-5 mb-4">STEP 3: REGISTER COMPANY</p>
                <p>
                  Thirdly, submit all the required information on the RJSC website.  Also, it is required to upload
                  Form IX  and Subscriber Page. After finishing all the process, you will receive a bank payment
                  slip for paying the registration fees along with stamp duty. After making the payment in the
                  bank and you are done. Now you have to follow up on the RJSC for obtaining the incorporation
                  certificate. RJSC officials will check the documents and information. If they are satisfied,
                  they will issue the digitally signed
                  <p>
                    <div className="d-flex align-items-center mb-2">
                      <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                      <span>
                        Certificate of Incorporation;
                      </span>
                    </div>

                    <div className="d-flex align-items-center mb-2">
                      <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                      <span>
                        MOA and AOA, and
                      </span>
                    </div>

                    <div className="d-flex align-items-center mb-2">
                      <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                      <span>
                        Form XII.
                      </span>
                    </div>
                    These documents will be mailed to your email address associated with your RJSC account <br></br>
                    There are cases when the incorporation procedure can get delayed if the shareholders or
                    directors are of certain nationalities. Although, this is a rare case. In such cases, the
                    authorities might ask for some additional information.
                  </p>
                </p>

              </div>
            </div>

            <div className="col-lg-6  "  >
              <img className="img-fluid" src={RJSCReturnFillingImg} alt='RJSC-Return-Filling-process-1' />
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl py-0">
        <div className="container">
          <div className="h-100">
            <p className="color-text fs-5 mb-4"><b>POST-REGISTRATION FORMALITIES for Company Formation</b></p>
            <p>Finally, you also need some documents that are issued by the Registrar of Joint Stock Companies
              and Firms (RJSC).
            </p>
            <p>
              <div className="d-flex align-items-center mb-2">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                  Certificate of Incorporation: RJSC will issue a Certificate of Incorporation of the company.
                  The certificate will contain the company registration number and name of the company the
                  date of incorporation.
                </span>
              </div>

              <div className="d-flex align-items-center mb-2">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                  Form XII: Form XII contains the list of particulars of directors of the incorporated company.
                </span>
              </div>

              <div className="d-flex align-items-center mb-2">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                  Certified copies of MOA and AOA.
                </span>
              </div>

              <p>Some of the other items you will almost need to register (company formation) of your
                Bangladeshi company. Like,
              </p>

              <div className="d-flex align-items-center mb-2">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                  Share certificates for each shareholder.
                </span>
              </div>

              <div className="d-flex align-items-center mb-2">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                  Register for shareholders, shares, directors, etc.
                </span>
              </div>

              <div className="d-flex align-items-center mb-2">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                  Company common seal for the company
                </span>
              </div>

              <div className="d-flex align-items-center mb-2">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                  A rubber stamp for the company
                </span>
              </div>
            </p>

            <p className="color-text fs-5 mb-4">
              <b>
                APPLYING FOR TRADE LICENSE, TAX IDENTIFICATION NUMBER, AND OTHER LICENSES
              </b>
            </p>
            <p>After the incorporation, you should manage a commercial space or rent some space in any
              commercial area.<br></br>Then you need to apply for Trade License, Tax Identification Number.
              Depending on your company’s business activities, you may need to obtain more business licenses.
            </p>
            <p className="color-text fs-5 mb-4">
              <b>
                RJSC RETURN FILING REQUIREMENTS
              </b>
            </p>
            <p>
              <div className="d-flex align-items-center mb-2">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                  <b>Annual Return:</b>  Each calendar year, an Annual General Meeting must be held. The AGM must be
                  conducted within 18 months of company incorporation, after which no more than 15 months can
                  elapse between one AGM and the next.
                </span>
              </div>
              <div className="d-flex align-items-center mb-2">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span>
                  <b>Regular Return:</b> In case of any change in the board of directors or in the shareholding structure
                  or any other change, a relevant return must be filed with the RJSC within a certain period of
                  time.
                </span>
              </div>
            </p>
            <p>
              So, Bangladesh is becoming one of the most promising outbound markets in the world. Due to the
              swift growth of the economy in this country, the company formation procedure is broadly
              asking questions to the new entrepreneurs. All the questions are answered above how you should
              register your company.
            </p>
          </div>
        </div>
      </div>

      <div className='container-xxl center-background1'>
                <h2> Related Articles</h2>
            </div>
            
            <div className='container-xxl center-background1'>
            <Marquee className="marquee-container">
               {
                  startupRelatedArticales
               }              
               
            </Marquee>
            </div>

      <div className="container py-5 my-0">
        <h2 className="color-text text-center mb-4">Company Formation FAQ</h2>

        <Faq items={faqItems} />
      </div>
             
    </>
  )
}

export default CompanyFormation