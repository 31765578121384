import React from "react";
import company_Formation_og_img from "../assets/og-img/company-formation.png";
import Meta from "./Meta";
export default function LegalSystem() {
  return (
    <>
      <Meta
        title="Company Formation in Bangladesh | Company Registration"
        description="Company Formation or Registration in Bangladesh. A private limited company in Bangladesh has a separate legal entity"
        image={company_Formation_og_img}
        keywords="Company Formation, Company Registration, Company Registration Bangladesh, private limited company"
      />
      <div className="container-xxl py-5">
        <div className="container">
          <h1 style={{ marginBottom: "20px" }} className="color-text display-6">
            Legal system of Bangladesh
          </h1>
          <p>
            Bangladesh is a common law based jurisdiction. Many of the basic
            laws of Bangladesh such as penal code, civil and criminal procedural
            codes, contract law and company law are influenced by English common
            laws. However family laws such as laws relating to marriage,
            dissolution of marriage and inheritance are based on religious
            scripts, and therefore differ between religious communities.
            <br />
            <br />
            The Bangladeshi legal system is based on a written constitution and
            the laws often take statutory forms which are enacted by the
            legislature and interpreted by the higher courts. Ordinarily
            executive authorities and statutory corporations cannot make any
            law, but can make by-laws to the extent authorized by the
            legislature. Such subordinate legislation is known as rules or
            regulations and is also enforceable by the court. Yet being a common
            law system, the statutes are short, and set out basic rights and
            responsibilities but are elaborated by the courts in their
            application and interpretation of those. In addition, certain
            customs and practices established over a period are also to some
            extent recognized as acceptable and are enforced by the courts.
            <br />
            <br />
            Since 1971 Bangladesh’s legal system has been updated in areas of
            company, banking, bankruptcy and Money Loan Court laws and other
            commercial laws.
          </p>
          <h2
            style={{ marginBottom: "20px", fontSize: "30px" }}
            className="color-text display-6"
          >
            Courts in Bangladesh:
          </h2>
          <p>
            The Judiciary of Bangladesh acts through the (i) The Superior
            Judiciary having Appellate, Revision & Original Jurisdiction & (2)
            Sub-Ordinate Judiciary having Original Jurisdiction
          </p>
          <div>
            <p
              className="color-text mb-4"
              style={{ fontSize: "23px", marginTop: "20px" }}
            >
              The Supreme Court:
            </p>
            <p>
              Bangladesh Supreme Court is the highest court of Bangladesh, have
              two divisions:
            </p>
            <ol style={{ color: "#5c5a5a" }}>
              <li>Appellate Division</li>
              <li>High Court Division</li>
            </ol>
          </div>
          
        </div>
      </div>
    </>
  );
}
