import React from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'react-bootstrap'
import Faq from '../../components/Faq'
import Meta from '../../components/Meta'
import ExportRegistrationCertificateImg from '../../assets/images/Export-Registration-Certificate.png'
import ExportRegistrationCertificateProcessiMG from '../../assets/images/Export-registration-certificate-process.png'
import RequiredDocumentsRENEWALOFERCImg from '../../assets/images/Required-Documents-RENEWAL-OF-ERC.png'
import export_og_img from "../../assets/og-img/Export Registration Certificate.png"
import Marquee from 'react-fast-marquee';
import { startupRelatedArticales } from '../../data/relatedArticale'


function ExportLicense() {

    const faqItems = [
        {
            title: 'How much is the Export license fee in Bangladesh?',
            content: <strong>Export license fee is Taka 7000 and renewal fee in the amount of Taka 5000.</strong>
        },
        {
            title: 'How do I get an Export permit?',
            content: <strong>EXPORTATION PROCEDURES AT THE BUREAU OF CUSTOMS.</strong>
        },
        {
            title: 'Why do I need an export permit?',
            content: <strong>Export of locally manufactured goods or local GST-paid goods.</strong>
        }
    ];

    return (
        <>
            <Meta title="Export License in Bangladesh | Export Business License"
                  description="A Export License can export anything from bangladesh to aboard. The Export Business License required others related business licenses like Trade license, TIN certificate, BIN "
                  image={export_og_img}
                  keywords="Export License in Bangladesh, Export License, Export Business License"
            />
                   

            <div className="container-fluid hero-header bg-light py-5 ">
                <div className="container py-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <h1 className="display-4 mb-3 animated slideInDown">Export License</h1>
                            <nav aria-label="breadcrumb animated slideInDown">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Startup</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Export License</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-6 animated fadeIn">
                            <img className="img-fluid animated pulse infinite" style={{ animationDuration: '3s' }} src={ExportRegistrationCertificateImg} alt='Export-Registration-Certificate' />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <p>
                        Anyone can Export any permissible item without any value and quantity restrictions and without
                        obtaining any permission from any authority in Bangladesh but the Export Registration
                        Certificate (ERC) or import license is needed for the import of the same.<br></br><br></br>
                        In Bangladesh, the Office of the Chief Controller of Imports and Exports (CCIE) which is an
                        individual organization under the Ministry of Commerce issues this Export Certificate or
                        Export license.<br></br><br></br>
                        So finally, Export Registration Certificate (ERC) or export license is a mandatory document
                        for business entities who are desirous of exporting any other special items from Bangladesh.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>Export Registration Certificate (ERC)</b>
                    </p>
                    <p>
                        <b>REQUIRED SUPPORTING DOCUMENTS:</b>
                    </p>

                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Attested photocopy of valid Trade License
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Attested photocopy of membership certificate from the local Chamber of Commerce and
                                Industry or relevant Trade Association.

                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Original copy of Treasury Chalan
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                If a partnership business: Partnership Deed (certified attested copy)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                If a limited company: (certified attested copies)
                            </span>
                        </div>
                        <span className='px-5'>1. Certificate of Incorporation</span><br />
                        <span className='px-5'>2. Articles of Association</span><br />
                        <span className='px-5'>3. Memorandum of Association</span>
                        <p>
                            (To facilitate verification of the submitted documents, applicants are requested to submit the
                            original documents as well. The original copies of the documents will be returned at the
                            completion of the process).
                        </p>
                    </p>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6  "  >
                            <img className="img-fluid" src={ExportRegistrationCertificateProcessiMG} alt='Export-registration-certificate-process' />
                        </div>
                        <div className="col-lg-6  "  >
                            <div className="h-100">
                                <p className="color-text fs-5 mb-4">PROCESS STEPS </p>
                                <p>
                                    <span>
                                        <b>Step 1:</b> Collect ERC form from the office of Controller Import and Export.
                                    </span>
                                    <br />

                                    <span>
                                        <b>Step 2:  </b>Deposit schedule fees at Bangladesh Bank or designated branches of
                                        Sonali Bank. Fees should be deposits under the head number: 1–1731–0001–1801
                                        (Sonali bank branches should be used only where Bangladesh Bank is not available).
                                        Collect and complete 3 copies of the Chalan Form and pay the scheduled fee.
                                    </span>
                                </p>
                                <Table striped bordered hover variant="light blue" >
                                    <thead>
                                        <tr>
                                            <th>Category of Certificate</th>
                                            <th>Initial Registration Fee</th>
                                            <th>Annual renewal fees</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Export Registration Certificate (ERC)</td>
                                            <td>Tk. 11,500 (Include VAT)</td>
                                            <td>Tk. 8,050 (Include VAT)</td>
                                        </tr>
                                        <tr>
                                            <td>Export Registration Certificate (Indenting Service)</td>
                                            <td>Tk. 57,500 (Include VAT)</td>
                                            <td>Tk. 28,750 (Include VAT)</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <p>
                                    <span>
                                        <b>Step 3:</b> Submit the application form with recovery supporting documents
                                    </span>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Attested a recent passport size photo of the applicant
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-1">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Make copies of the complete application package for self
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-1">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Submit application at the receipt room 3rd floor of CIE by 4 p.m.
                                        </span>
                                    </div>
                                </p>
                                <p>
                                    Collect the application registration number.<br></br><br></br>
                                    Anyone can Export any permissible item without any value and quantity
                                    restrictions and without obtaining any permission from any authority in
                                    Bangladesh but the Export Registration Certificate (ERC) or import license
                                    is needed for the import of the same. In Bangladesh, the Office of the Chief
                                    Controller of Imports and Exports (CCIE) which is an individual organization
                                    under the Ministry of Commerce issues this Export certificate or Export
                                    license. So finally, Export Registration Certificate (ERC) or export license
                                    is a mandatory document for business entities who are desirous of exporting
                                    any other special items from Bangladesh.

                                </p>
                                <p className="color-text fs-5 mb-4">
                                    The procedure of issuance of Import license in Bangladesh
                                </p>
                                <p>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            <b>Stage-01: Application Filing</b>
                                        </span>
                                    </div>
                                    <p>
                                        Submit the application form along with the prescribed fee and to the Chief
                                        Controller of Imports & Exports (CCIE). Anyone can file the application for
                                        obtaining the export Registration Certificate (ERC) which is well known as Import
                                        License.
                                    </p>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            <b>Stage-02: Receive the Certificate</b>
                                        </span>
                                    </div>
                                    <p>
                                        After formalities checking the submitted documents, the office of the Chief
                                        Controller of Imports & Exports will issue the license in favor of the
                                        applicant.
                                    </p>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-0">
                <div className="container">
                    <p className="color-text fs-5 mb-4">
                        <b>Stage-03: Renewal of IRC</b>
                    </p>
                    <p>
                        ERC is required to be renewed every year. The required documents are: <br></br>
                        1. ERC and Passbook ( Original copy and Two set Photo Copy)<br></br>
                        2. Renewal Application by the applicant<br></br>
                        3. Treasury Challan of the annual renewal fee at Bangladesh Bank or Sonali Bank (Original copy and Two set Photo Copy) <br></br>
                        The applicant is required to arrange the below-mentioned documents and deposit the annual renewal fee at Bangladesh Bank or Sonali Bank <br></br>
                        **Official/Government Fees for Renewal is the same as below mentioned
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>Required Documents</b>
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Membership Certificate from recognized Chamber/Trade Association (Original)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Valid Trade License (Original)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                eTIN Certificate
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                VAT registration certificate
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Bank Certificate
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Memorandum and Articles of Association (in case of Limited Company)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Certificate of Incorporation (in case of Limited Company)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Partnership deed (in case of Partnership firm)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Office rent deed
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Two copies photograph of the applicant
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                NID/Passport of the applicant
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Treasury Challan of the required fee deposited (Original)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Any other documents as required
                            </span>
                        </div>
                        <b>Note:</b> All documents are required 2 extra sets with duly attested by a First Class gazetted officer or Notary Public or First Class Magistrate
                    </p>

                    <p className="color-text fs-5 mb-4">
                        <b>The documents required for issuance of ERC </b>
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Passport size photograph of the applicant
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Valid Trade License
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Valid Membership Certificate from a regional Chamber of Commerce and Industry or from
                                relevant Trade Associations.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Citizenship Certificate of the applicant
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Treasury Challan
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                TIN Certificate
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Partnership Deed
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Certificate of Incorporation, Memorandum, and Articles of Association
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Financial Solvency Certificate
                            </span>
                        </div>

                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>Procedure </b>
                    </p>
                    <p>
                        The applicant is required to collect the Form of IRC from the CCI&E office or CCI&E website and
                        submit the filled-in form along with supporting documents to the Dispatch Section of CCI&E
                        office. Registration fee and the cost of pass-book should be deposited at the Bangladesh Bank
                        or Sonali Bank and three (3) copies of Treasury Challan shall be collected.<br></br><br></br>
                        In order to receive the Import Registration Certificate, the applicant needs to submit the
                        filled-in application form along with the listed supporting documents and Treasury Challan at
                        CCI&E office
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>Time Limit </b>
                    </p>
                    <p>
                        The estimated processing time for issuance of ERC is four to five working days.
                    </p>
                    <p>
                        <img className="mx-auto responsive-img" src={RequiredDocumentsRENEWALOFERCImg} alt='Required-Documents-RENEWAL-OF-ERC' style={{ width: 1400, height: 300 }} />
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>Renewal of ERC</b>
                    </p>
                    <p>
                        IRC is required to be renewed every year. The required documents are:<br></br>
                        1. Original copy of ERC and ERC Passbook<br></br>
                        2. Treasury Challan<br></br>
                        The applicant is required to arrange the above-mentioned documents and deposit the annual renewal
                        fee at Bangladesh Bank or Sonali Bank
                    </p>
                    <p className="color-text fs-5 mb-2">
                        <b>Time limit</b>
                    </p>
                    <p>
                        The estimated processing time for the renewal of ERC is 4-5 working days.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>Scheduled government fee : BDT 8,050</b>
                    </p>

                </div>
            </div>

            <div className='container-xxl center-background1'>
                <h2> Related Articles</h2>
            </div>
            
            <div className='container-xxl center-background1'>
            <Marquee className="marquee-container">
               {
                startupRelatedArticales
               }               
            </Marquee>
            </div>

            <div className="container py-5 my-0">
                <h2 className="color-text text-center mb-4">Export License FAQ</h2>
                <Faq items={faqItems} />
            </div>

                   
        </>
    )
}

export default ExportLicense