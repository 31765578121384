import React from 'react';
import BlogComponent from '../../components/BlogComponent';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import { blogPosts } from '../../data/blogPosts ';
import { Link } from 'react-router-dom';

function BlogCard() {
  return (
    <div className="App">
      <h2 style={{ textAlign: 'center', marginTop: '150px' }}>Latest Blog Posts</h2>
      <div className="blog-grid container-xxl py-5">
        {blogPosts.map((post, index) => (
          <BlogComponent
            key={index}
            image={post.image}
            title={post.title}
            summary={post.summary}
            author={post.author}
            date={post.date}
          />
        ))}
      </div>
             
    </div>
  );
}

export default BlogCard;
