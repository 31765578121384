import React from "react";
import company_Formation_og_img from "../assets/og-img/company-formation.png";
import Meta from "./Meta";
import { licenseList } from "../data/licenseAndAprovalList";
import { Table } from "react-bootstrap";

export default function LicenseAndApproval() {
  return (
    <>
      <Meta
        title="Company Formation in Bangladesh | Company Registration"
        description="Company Formation or Registration in Bangladesh. A private limited company in Bangladesh has a separate legal entity"
        image={company_Formation_og_img}
        keywords="Company Formation, Company Registration, Company Registration Bangladesh, private limited company"
      />
      <div className="container-xxl py-5">
        <div className="container">
          <h1 style={{ marginBottom: "20px",fontSize:"2.12rem" }} className="color-text display-6">
           Business License and Approval in Bangladesh
          </h1>
          <p>
            Below we have compiled a list of
            licenses/approvals/permits/certificates, which are most frequently
            required for doing business in Bangladesh. Please note that there
            are some other licenses/approvals/permits, which are not mentioned
            here.
            <br />
            <br />
            Unless you are conducting your business as a proprietorship, you
            will be required to register a Partnership or Company prior to
            obtaining these licenses/approvals/permits/certificate.
          </p>
         {
          <Table className="table" striped bordered hover variant="light blue">
          <thead>
            <tr>
              <th scope="col">Required Business Licenses for Bangladesh</th>
              <th scope="col">Licenses Approval Authority</th>
            </tr>
          </thead>
          <tbody>
            {
              licenseList.map((item,index)=>( <tr key={index}>
                <td>
                {item?.licenseName}
                </td>
                <td>
                {item?.agency}
                  </td>
              </tr>))
            }
           
           
          </tbody>
        </Table>
         }
        
        </div>
      </div>
    </>
  );
}
