import React from 'react'
import { Link } from 'react-router-dom'
import Faq from '../../components/Faq';
import Meta from '../../components/Meta';
import CivilLitigationImg from "../../assets/images/Civil-Litigation.png"
import TypesofCivilLitigation from "../../assets/images/Types-of-Civil-Litigation.png"
import civil_og_Img from "../../assets/og-img/Civil Litigation.png"
import Marquee from 'react-fast-marquee';
import { othersArticales } from '../../data/relatedArticale';

function CivilLitigationScreen() {
    const faqItems = [
        {
            title: 'What is Civil Litigation?',
            content: <strong>Civil Litigation and Civil Law, covers an extensive range of legal disputes between
                two or more parties regarding civil matters.</strong>
        },
        {
            title: 'What Does Litigation Mean?',
            content: <strong>Litigation is a term that references the process of pursuing legal action.</strong>
        },
        {
            title: 'How Litigation Typically Proceeds?',
            content: <strong>Litigation is a complex process made even more complicated in certain
                circumstances, as with parallel proceedings.</strong>
        }
    ];
    return (
        <>
            <Meta title="Civil Litigation"
                description="This page provides information about BSTI permission and related processes."
                image={civil_og_Img}
                keywords="keyword1, keyword2, keyword3"
            />
            
                   

            <div className="container-fluid hero-header bg-light py-5 mb-5">
                <div className="container py-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <h1 className="display-4 mb-3 animated slideInDown">Civil Litigation</h1>
                            <nav aria-label="breadcrumb animated slideInDown">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Litigation</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Civil Litigation</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-6 animated fadeIn">
                            <img className="img-fluid animated pulse infinite" style={{ animationDuration: '3s' }} src={CivilLitigationImg} alt='Civil-Litigation' />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <p>
                        Civil litigation is the process of resolving disputes between individuals, organizations or
                        businesses through the court system. It typically involves one party (the plaintiff) suing
                        another party (the defendant) for damages or some other form of legal relief.<br /><br />

                        Examples of civil litigation cases include personal injury lawsuits, contract disputes,
                        property disputes, employment disputes, and family law cases such as divorce and child
                        custody battles.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>What is Civil Litigation?</b>
                    </p>
                    <p>
                        Civil litigation is a legal process in which two or more parties, who are not involved in
                        criminal proceedings, attempt to resolve a dispute through the court system. It is a process
                        of resolving legal disputes between individuals, businesses, or organizations through the
                        court system, where one party brings a legal claim against another in a civil court. Civil
                        litigation can involve a wide range of issues, including contract disputes, personal
                        injury claims, property disputes, employment matters, and many other types of legal
                        disputes.<br /><br />

                        Civil litigation typically involves several stages, including pre-trial discovery, motion
                        practice, settlement negotiations, and trial. During pre-trial discovery, both parties
                        exchange information and evidence related to the case. Motion practice involves the parties
                        submitting various legal motions to the court, such as motions to dismiss or motions for
                        summary judgement. Settlement negotiations may take place at any stage of the litigation
                        process, and involve the parties attempting to reach a settlement outside of court. If the
                        case does not settle, it will proceed to trial, where a judge or jury will hear the evidence
                        and render a decision.
                    </p>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6  "  >
                            <img className="img-fluid" src={TypesofCivilLitigation} alt='Types-of-Civil-Litigation' />
                        </div>
                        <div className="col-lg-6  "  >
                            <div className="h-100">
                                <p className="color-text fs-5 mb-4">Types of Civil Litigation</p>
                                <p>
                                    Civil litigation refers to the legal process in which one party, known as the
                                    plaintiff, sues another party, known as the defendant, in order to seek
                                    compensation or some other form of relief. Here are some common types of civil
                                    litigation:
                                </p>
                                <p><b>1. Personal Injury: </b>If one person has suffered harm or injury due to the
                                    actions or negligence of another party, and seeks compensation for damages such as
                                    medical expenses, lost wages, and pain and suffering.
                                </p>

                                <p><b>2. Contract Disputes:</b> Where parties have entered into a legal agreement
                                    and one party has breached the terms of the agreement, resulting in a dispute.
                                </p>

                                <p>
                                    <b>3. Employment Disputes:</b> An employee or employer has been mistreated,
                                    discriminated against, or wrongfully terminated, and seeks compensation or other
                                    relief.
                                </p>
                                <p>
                                    <b>4. Real Estate Disputes:</b> A dispute over ownership, use, or possession of
                                    real property, or over the terms of a real estate contract.
                                </p>
                                <p>
                                    <b>5. Intellectual Property Disputes: </b>If there is a dispute over ownership
                                    or infringement of patents, trademarks, copyrights, or trade secrets.
                                </p>
                                <p>
                                    <b>6. Debt Collection:</b> If a creditor seeks to collect a debt from a debtor,
                                    often through legal action.
                                </p>
                                <p>
                                    <b>7. Landlord-Tenant Disputes:</b> Where there is a dispute between a landlord
                                    and tenant over issues such as rent payments, eviction, or repairs.
                                </p>
                                <p>
                                    <b>8. Medical Malpractice:</b> A healthcare professional has acted negligently
                                    or inappropriately, resulting in harm to a patient.
                                </p>
                                <p>
                                    <b>9. Product Liability:</b> A manufacturer or seller of a product is held
                                    liable for harm caused by a defective or dangerous product.
                                </p>
                                <p>
                                    <b>10. Insurance Disputes:</b> If there is a dispute over coverage, payment, or
                                    interpretation of an insurance policy.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-0">
                <div className="container">
                    <p className="color-text fs-5 mb-4">
                        <b>Steps to follow in a Civil law case</b>
                    </p>
                    <p>
                        Here are the typical steps to follow in a civil litigation case:
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Consult with an attorney:</b>If you decide to proceed with a lawsuit, your attorney will file a formal complaint with the court, detailing the facts of your case and the legal claims you are making against the defendant.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Filing the Complaint: </b>If you decide to proceed with a lawsuit, your attorney will
                                file a formal complaint with the court, detailing the facts of your case and the legal
                                claims you are making against the defendant.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Service of Process: </b>The defendant must be served with a copy of the complaint and
                                a summons to appear in court. This is usually done by a process server or sheriff.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Answer: </b>The defendant will have a certain amount of time to respond to the
                                complaint, either admitting or denying the allegations.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Discovery: </b>Both parties will engage in the discovery process, which involves
                                exchanging information and evidence related to the case. This can include depositions,
                                interrogatories, and requests for documents.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Motions: </b>Either party may file motions with the court, requesting a ruling on a
                                particular issue or requesting that certain evidence be excluded.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Settlement negotiations: </b>Parties may engage in settlement negotiations at any
                                point during the litigation process.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Trial: </b>If the case is not settled, it will go to trial, where each side will
                                present evidence and argue their case to a judge or jury.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Verdict and Judgement: </b>The judge or jury will render a verdict, and if the
                                plaintiff is successful, the court will enter a judgement in their favor.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Appeals: </b>Either party may appeal the judgement to a higher court if they believe
                                there were errors made in the trial or judgement.
                            </span>
                        </div>
                    </p>

                    <p className="color-text fs-5 mb-4">
                        <b>What is a litigator?</b>
                    </p>
                    <p>
                        A litigator is a lawyer who specializes in representing clients in civil litigation cases.
                        They are responsible for managing all aspects of a case, including researching the facts and
                        the law, drafting pleadings and legal documents, negotiating with opposing parties, and
                        advocating for their clients in court.<br /><br />

                        Litigators work in a variety of practice areas, including personal injury law, employment
                        law, business litigation, and real estate disputes. They may represent individual clients,
                        small businesses, or large corporations, depending on their area of expertise.<br /><br />

                        A Litigator must have strong analytical and communication skills, as well as the ability to
                        think strategically and adapt to changing circumstances. They must also be well-versed in the
                        rules of civil procedure and have a deep understanding of the legal issues involved in their
                        clients’ cases.<br /><br />

                        Overall, litigators play a critical role in helping clients navigate the complex legal system
                        and achieve a positive outcome in their civil litigation cases.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>Role of a Civil Litigation Attorney</b>
                    </p>
                    <p>
                        A civil litigation attorney is a legal professional who represents clients in non-criminal
                        legal disputes, such as contract disputes, personal injury cases, and property disputes.
                        Here are some of the key roles and responsibilities of a civil litigation attorney:
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Assessing the case: </b>He will evaluate the merits of a client’s case and advise
                                them on the best course of action.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Investigation: </b>Attorney will conduct a thorough investigation into the facts of
                                the case, including gathering evidence and interviewing witnesses.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Legal research: </b>A civil litigation attorney will research the relevant laws and
                                regulations that apply to the case, and use that information to build a strong legal
                                argument.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Drafting legal documents: </b>He will prepare legal documents such as complaints,
                                motions, and pleadings.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Representing clients in court: </b>A civil litigation attorney will represent clients
                                in court and argue their case before a judge or jury.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Negotiation: </b>This man will attempt to negotiate a settlement between the parties
                                before the case goes to trial.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Client communication: </b>He will keep clients informed about the progress of the
                                case and advise them on important decisions.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Advocacy: </b>He would advocate on behalf of their client, working to ensure that
                                their legal rights are protected and their interests are represented.
                            </span>
                        </div>
                        Overall, a civil litigation attorney plays a critical role in helping clients navigate the
                        complex legal system and achieve a positive outcome in their case.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>Confusion regarding Civil Litigation</b>
                    </p>
                    <p>
                        Here are some common areas of confusion regarding civil litigation:
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>The difference between civil litigation and criminal litigation: </b>Civil litigation
                                involves non-criminal disputes between parties, such as contract disputes, personal
                                injury cases, and property disputes, while criminal litigation involves cases where
                                a person is charged with a crime.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>The burden of proof: </b>The burden of proof is typically lower than in criminal
                                cases. In civil cases, the plaintiff must prove their case by a preponderance of the
                                evidence, while in criminal cases, the prosecution must prove guilt beyond a reasonable
                                doubt.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Time frame: </b>These cases can take a long time to resolve, often lasting months or
                                even years, while criminal cases typically move more quickly through the court system.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Settlement vs. trial: </b>Many cases are settled out of court through negotiation,
                                while criminal cases typically go to trial.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Cost: </b>It can be expensive, as attorneys’ fees and court costs can add up quickly.
                                Criminal defendants are typically provided with a public defender if they cannot afford
                                an attorney.
                            </span>
                        </div>
                        It’s important to consult with a qualified attorney if you have questions about civil litigation,
                        as they can help clarify any areas of confusion and guide you through the legal process.
                    </p>

                    <p className="color-text fs-5 mb-4">
                        <b>Recommendation to get better Civil Litigation help </b>
                    </p>
                    <p>
                        If you are seeking the best service for civil litigation, here are some recommendations:
                    </p>

                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Research and find a reputable law firm or attorney: </b>Look for a law firm or attorney
                                with a good track record and reputation in civil litigation. You can ask for
                                recommendations from friends, family, or other professionals in the legal industry.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Experience and expertise:</b> A law firm or attorney with experience and expertise in civil
                                litigation. Make sure they have handled similar cases to yours and have a good success
                                rate.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Communication: </b>Find a law firm or attorney that is easy to communicate with and keeps
                                you informed throughout the litigation process. They should be responsive to your
                                queries and keep you updated on the progress of your case.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Cost: </b>Consider the cost of the service and ensure that it is within your budget. You can
                                discuss the cost upfront with the law firm or attorney to avoid any surprises later on.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b> Compatibility: </b>Choose a law firm or attorney that you are comfortable working with. You
                                should feel confident in their abilities and trust their advice.
                            </span>
                        </div>
                        Overall, the key is to do your research and choose a law firm or attorney that fits your needs
                        and has the expertise to handle your civil litigation case effectively.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>Civil Litigation Help or Advise </b>
                    </p>
                    <p>
                        <b>“JK Associates”</b> is a top-ranked Corporate Consultancy and Law firm in Bangladesh with
                        an international presence providing specialized services in Intellectual Property Rights,
                        Corporate Affairs, Foreign Trade and Investment, Taxation, and Litigation. <b>‘JK Associates’</b> is
                        always there to give you legal help or advice of your need.
                    </p>
                    <p>
                        ☎ 02-8090785 (9.30am – 6.30pm), +8801511-404971, +8801973-404971, +8801601-404971<br />
                        📧 info@jkassociates.com.bd<br />
                        📍 Head Office: 752/2, City Bank Building (6th floor) Begum Rokeya Ave, Mirpur, Dhaka 1216<br />
                        📍 Branch: 74, RH Home Center, B/1 Green Road Dhaka- 1215<br />
                        Get Directions or Send a Message
                    </p>
                </div>
            </div>

            <div className='container-xxl center-background1'>
                <h2> Related Articles</h2>
            </div>

            <div className='container-xxl center-background1'>
                <Marquee className="marquee-container">
                   {
                    othersArticales
                   }
                </Marquee>
            </div>

            <div className="container py-5 my-0">
                <h2 className="color-text text-center mb-4">Civil Litigation FAQ</h2>
                <Faq items={faqItems} />
            </div>

                   
        </>
    )
}

export default CivilLitigationScreen