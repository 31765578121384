import React from "react";
import company_Formation_og_img from "../assets/og-img/company-formation.png";
import Meta from "./Meta";

export default function CompanyRegistrationInBD() {
  return (
    <>
      <Meta
        title="Company Formation in Bangladesh | Company Registration"
        description="Company Formation or Registration in Bangladesh. A private limited company in Bangladesh has a separate legal entity"
        image={company_Formation_og_img}
        keywords="Company Formation, Company Registration, Company Registration Bangladesh, private limited company"
      />
      <div className="container-xxl py-5">
        <div className="container">
          <h1 style={{ marginBottom: "20px" }} className="color-text display-6">
            Company registration in Bangladesh
          </h1>
          <p>
            Welcome to our comprehensive guide on registering a private limited
            company in Bangladesh with foreign shareholders. This guide aims to
            provide you with detailed insights into the requirements,
            procedures, and timelines involved in setting up your business
            entity in Bangladesh, ensuring compliance with the country’s
            regulatory framework.
            <br />
            <br />
            Bangladesh, like many other jurisdictions, maintains specific
            regulatory protocols for company registration, both at the outset
            and throughout the operational phase. The predominant form of
            business entity in Bangladesh is the private limited liability
            company, offering a favorable legal structure for entrepreneurs.
            While Bangladesh does allow for the formation of one-person
            companies (OPCs), this option is restricted to local individuals and
            not available to foreign investors.
            <br />
            <br />
            A private limited company in Bangladesh is a separate legal entity
            and shareholders are not liable for the company’s debts beyond the
            amount of share capital they agree to contribute.
            <br />
            <br />
            In the subsequent sections, we’ll delve into the intricacies of
            company registration, outlining the steps involved, necessary
            documentation, and the regulatory landscape to ensure a smooth and
            compliant establishment of your business presence in Bangladesh.
            <br />
            <br />
            If you are a foreign investor, we recommend you to read our article
            on foreign investment. It will help you to understand the legal
            regime about foreign investment in Bangladesh.
          </p>
          <h2
            style={{ marginBottom: "20px", fontSize: "30px" }}
            className="color-text display-6"
          >
            Pre-Registration – What You Need to Know
          </h2>
          <p
            className="color-text mb-4"
            style={{ fontSize: "23px", marginTop: "20px" }}
          >
            Key facts about company formation
          </p>
          <p
            className="color-text mb-4"
            style={{ fontSize: "23px", marginTop: "20px" }}
          >
            The Registrar of Joint Stock Companies and Firms (RJSC)
          </p>
          <p>
            Commonly referred to as RJSC, serves as the primary regulatory body
            responsible for overseeing company registration and maintenance of
            company records in Bangladesh. Its core responsibilities include the
            registration of companies and the acceptance of returns, thereby
            ensuring the accuracy and currency of company related information.
            <br />
            <br />
            Additionally, RJSC plays a pivotal role in facilitating procedures
            related to company closures, including winding up and striking off
            companies from the register.
          </p>
          <p
            className="color-text mb-4"
            style={{ fontSize: "23px", marginTop: "20px" }}
          >
            Company Name Approval{" "}
          </p>
          Before incorporation, the proposed company name must be approved by
          RJSC (i.e. cleared).
        </div>

        <p
          className="color-text mb-4"
          style={{ fontSize: "23px", marginTop: "20px" }}
        >
          Directors:
        </p>
        <p>
          A minimum of two directors is mandatory, who can be either local or
          foreign individuals. Directors must be at least 18 years old, free
          from bankruptcy, and not convicted of any crime. Additionally,
          directors must hold qualification shares as stated in the Articles of
          Association. Nominee directors can be appointed by corporate
          shareholders
        </p>
        <p
          className="color-text mb-4"
          style={{ fontSize: "23px", marginTop: "20px" }}
        >
          Shareholders:
        </p>
        <p>
          A private limited company can have 2 to 50 shareholders, who may be
          individuals or legal entities, including other companies. Shareholders
          and directors can be the same or different persons. 100% foreign
          shareholding is allowed in most sectors. New shares can be issued, or
          existing shares can be transferred to another person any time after
          the Bangladeshi company has gone through the incorporation process.
        </p>
        <p
          className="color-text mb-4"
          style={{ fontSize: "23px", marginTop: "20px" }}
        >
          Authorized Capital
        </p>

        <p>
          In the Memorandum of Association, the authorised capital must be
          stated. It is the maximum amount of share capital that the company is
          authorized to issue (allocate) to shareholders. Part of the authorized
          capital can remain unissued. There is no minimum or maximum limit for
          authorized capital in Bangladesh.
        </p>
        <p
          className="color-text mb-4"
          style={{ fontSize: "23px", marginTop: "20px" }}
        >
          Paid-up Capital:
        </p>

        <p>
          The minimum paid-up capital for company registration is Taka 1.
          Paid-up capital, also known as share capital, can be increased
          post-incorporation.
          <br />
          <br />
          Government fees: Government fees associated with company incorporation
          in Bangladesh are determined based on the amount of authorized capital
          declared by the company. These fees comprise various components,
          including registration fees, stamp duty, registration filing fees, and
          the cost of certified copies.
        </p>
        <p
          className="color-text mb-4"
          style={{ fontSize: "23px", marginTop: "20px" }}
        >
          Registered Address
        </p>

        <p>
          A local physical address (residential or commercial) must be provided
          as the registered address of the company. P.O. Box addresses are not
          accepted.
          <br />
          <br />
          Memorandum and Articles of Association: The Memorandum of Association
          (MoA) and Articles of Association (AoA) are essential documents
          outlining the operational and governance framework of the company. The
          business object should be clearly stated in 1000 words or less and 7
          clauses.
        </p>

        <p
          className="color-text mb-4"
          style={{ fontSize: "23px", marginTop: "20px" }}
        >
          Memorandum of Association (MoA):
        </p>
        <p>
          The MoA articulates the primary business objectives that the company
          intends to pursue. It serves as a foundational document, defining the
          scope of the company’s business operations. If a company wants to
          change the line of business that is mentioned in the MoA, permission
          from the court will be required.
        </p>
        <p
          className="color-text mb-4"
          style={{ fontSize: "23px", marginTop: "20px" }}
        >
          Articles of Association (AoA)
        </p>
        <p>
          The AoA governs the internal management and operational procedures of
          the company. It regulates the relationships between directors and
          shareholders, outlining their respective rights and responsibilities.
          In the case of joint ventures, it is crucial to incorporate any
          specific terms and conditions agreed upon by the joint venture
          partners into the AoA. This preemptive measure helps mitigate
          potential conflicts and ensures smooth collaboration among partners.
        </p>
        <p
          className="color-text mb-4"
          style={{ fontSize: "23px", marginTop: "20px" }}
        >
          Bank Account
        </p>
        <p>
          Opening a bank account is mandatory for incorporating a company with
          foreign shareholders. The bank account needs to be opened in the name
          of the proposed company as mentioned in the name clearance issued by
          RJSC. In our experience, foreign shareholders sometimes find it
          difficult to open a bank account as banks may request documents that
          are impossible to produce (as these documents become available only
          after incorporation is complete). The reason behind this difficulty
          lies in the banks’ unfamiliarity with the account opening process for
          foreign shareholders.
          <br />
          <br />
          The proposed directors of the proposed company will have to hold a
          meeting and pass a resolution in relation to the opening and operation
          of the bank account.
        </p>
        <div>
          <p
            className="color-text mb-4"
            style={{ fontSize: "23px", marginTop: "20px" }}
          >
            Initial Capital Deposit
          </p>
          <p>
            After the bank account is opened, foreign shareholders need to remit
            the initial capital into the bank account. This capital injection is
            a prerequisite before proceeding with the submission of documents to
            the RJSC for official registration. For the remittance, the bank
            will issue an “Encashment Certificate,” which needs to be submitted
            to the RJSC.
          </p>
        </div>
        <div>
          <p
            className="color-text mb-4"
            style={{ fontSize: "23px", marginTop: "20px" }}
          >
            Remote Incorporation Formalities
          </p>
          <p>
            In most cases, company incorporation procedures can be completed
            remotely without directors or shareholders needing to physically
            visit Bangladesh, except for potential bank account opening
            requirements. The bank may require the presence of signatories for
            their KYC (Know Your Client) requirements.
          </p>
        </div>
        <div>
          <p
            className="color-text mb-4"
            style={{ fontSize: "23px", marginTop: "20px" }}
          >
            Local director/ shareholder
          </p>
          <p>
            Except for a few businesses, in most cases, all the directors and
            shareholders can be foreigners.
          </p>
        </div>
        <div>
          <p
            className="color-text mb-4"
            style={{ fontSize: "23px", marginTop: "20px" }}
          >
            Visa and Work Permit
          </p>
          <p>
            Investors can operate the company from overseas without coming to
            Bangladesh. If required, foreign investors can come to Bangladesh
            with a business visa. No work permit is required unless the
            investors plan to relocate to Bangladesh. However, if relocation to
            Bangladesh is planned, obtaining a work permit is necessary for
            investors or foreign employees. In such cases, equity investment of
            US$ 50,000 needs to be brought in.
          </p>
        </div>
        <div>
          <p
            className="color-text mb-4"
            style={{ fontSize: "23px", marginTop: "20px" }}
          >
            Required Documents
          </p>
          <p>
            For company incorporation in Bangladesh, the following information
            is required by the company registrar:
            <ul style={{ color: "#5c5a5a" }}>
              <li>
                <b>Name Clearance </b>: Based on your application, RJSC will
                issue a name clearance.
              </li>
              <li>
                <b>Memorandum of Association and Articles of Association </b>:
                RJSC requires that the object clause in the MoA be within 1000
                words and 7 clauses.
              </li>
              <li>
                <b>Shareholders and Directors Particulars</b>: name, address,
                parents’ names, passport, Photo of the shareholder.
              </li>
              <li>
                <b>Registered Address</b>: A local address is required. It can
                be residential or commercial or industrial space.
              </li>
              <li>
                <b> Signed Form IX and Subscriber Page</b>: Scanned copies of
                these documents will be required.
              </li>
              <li>
                <b> Encasement Certificate</b>: will be issued by the bank
                stating how much foreign remittance the bank received
              </li>
            </ul>
            <p>
              Please note that the bank may require additional documents for
              bank account opening.
            </p>
          </p>

          <div>
            <h2
              style={{ marginBottom: "20px", fontSize: "30px" }}
              className="color-text display-6"
            >
              Registration Procedure
            </h2>
            <p>
              Company registration procedure in Bangladesh is partially
              computerized.
              <br />
              <br />
              There are five distinct steps involved in the Bangladeshi company
              setup procedure: i) Name Clearance; ii) Bank account opening and
              bringing in the paid-up capital; iii) Submission of information
              and uploading of documents on the RJSC website and payment of
              government fees; iv) verification of Encashment Certificate; and
              finally, v) Incorporation.
            </p>
            <div>
              <p
                className="color-text mb-4"
                style={{ fontSize: "23px", marginTop: "20px" }}
              >
                Step 1- Choosing the Partnership Name
              </p>
              <p>
                To set up a Bangladesh company, your first step would be to
                obtain a name clearance for the proposed company name. You will
                have to visit www.roc.gov.bd and create a username first. Then
                you will be able to apply for name clearance. After you make the
                application for name clearance, you will receive a bank payment
                slip and you will have to pay Taka 200 to the designated bank.
                After making the payment, you will have to log in to your
                account on the RJSC website and then you will get the name
                clearance.
                <br />
                <br />
                You may read this guide prepared by RJSC for name clearance.
                <br />
                <br />
                Tip: the website of RJSC may not work on some browser. You
                should try a different browser if you face any problem. Also,
                during holidays in Bangladesh, RJSC may shutdown their server.
                <br />
                <br />
                To improve your chances of quick name approval, make sure the
                name:
                <ul style={{ color: "#5c5a5a" }}>
                  <li>
                    is not identical or too similar to any existing company
                    names;
                  </li>
                  <li>does not infringe with any trademarks;</li>
                  <li>is not obscene or vulgar; and</li>
                  <li>is not already reserved.</li>
                </ul>
                <p>
                  An approved name will be reserved for 2 months from the date
                  of clearance. You can extend the name by filing a new
                  application.
                </p>
              </p>
            </div>
            <div>
              <p
                className="color-text mb-4"
                style={{ fontSize: "23px", marginTop: "20px" }}
              >
                Step 2- Bank Account Opening and Bringing in the Paid-Up Capital
              </p>
              <p>
                Next, as part of the company incorporation process, you will be
                required to open a bank account in the proposed company name
                with any scheduled bank in Bangladesh. This bank account will
                serve as the official financial hub for the company’s operations
                within the country.
                <br />
                <br />
                Once the bank account is successfully opened, the next step
                involves remitting the funds equivalent to the shares to be
                owned by the foreign shareholder from outside Bangladesh into
                this account. This capital injection demonstrates the financial
                commitment of the shareholders to the company and is a
                prerequisite for completing the incorporation process.
                <br />
                <br />
                Upon receipt of the remitted funds, the bank will issue an
                Encashment Certificate. This certificate serves as formal
                documentation of the foreign currency being converted into local
                currency and deposited into the company’s bank account. The
                Encashment Certificate is a crucial document that will be
                verified by RJSC during the company incorporation process.
              </p>
            </div>
            <div>
              <p
                className="color-text mb-4"
                style={{ fontSize: "23px", marginTop: "20px" }}
              >
                Step 3: Uploading of documents on the RJSC website
              </p>
              <p>
                The next step is to submit all the required information to the
                RJSC’s website. Also, you will be required to upload scan copies
                of Form IX and Subscriber Page and the Encashment Certificate.
                After you finish all the process, you will receive a bank
                payment slip for paying the registration fees along with stamp
                duty.
                <br />
                <br />
                You may read this guide prepared by RJSC to submit all the
                information on the RJSC’s website.
                <br />
                <br />
                After making the payment to the bank, RJSC will start reviewing
                your documents and information.
              </p>
            </div>
            <div>
              <p
                className="color-text mb-4"
                style={{ fontSize: "23px", marginTop: "20px" }}
              >
                Step 4: verification of Encashment Certificate.
              </p>
              <p>
                After initial verification, if the officials of RJSC are
                satisfied, they will issue a letter to the bank that issued the
                Encashment Certificate. Once they receive a response from the
                bank confirming the authenticity of the Encashment Letter, RJSC
                will proceed to complete the registration process.
              </p>
            </div>
            <div>
              <p
                className="color-text mb-4"
                style={{ fontSize: "23px", marginTop: "20px" }}
              >
                Step 5: Incorporation.
              </p>
              <p>
                Finally, RJSC will issue the digitally signed i) Certificate of
                Incorporation; ii) MoA and AoA; and iii) Form XII. These
                documents will be mailed to your email address associated with
                your RJSC account.
                <br />
                <br />
                There are cases when the incorporation procedure can get delayed
                if the shareholders or directors are of certain nationalities,
                although this happens in rare cases only. In such cases, the
                authorities might ask for additional information.
              </p>
              <p
                className="color-text mb-4"
                style={{ fontSize: "23px", marginTop: "20px" }}
              >
                Documents Issued by RJSC:
              </p>
              <ul style={{ color: "#5c5a5a" }}>
                <li>
                  <b>Certificate of Incorporation</b>: RJSC will issue a
                  Certificate of Incorporation of the company. The certificate
                  will have the registration number, name of the company and the
                  date of incorporation.
                </li>
                <li>
                  <b>Form XII</b>: Form XII contains the list of directors of
                  the incorporated company.
                </li>
                <li>
                  <b>Certified copies of MoA and AoA</b>
                </li>
              </ul>
              <p>
                Some of the other items you will almost certainly need upon
                registration of your Bangladeshi company include:
              </p>
              <ul style={{ color: "#5c5a5a" }}>
                <li>
                  <b>Certificate of Incorporation</b>: RJSC will issue a
                  Certificate of Incorporation of the company. The certificate
                  will have the registration number, name of the company and the
                  date of incorporation.
                </li>
                <li>Share certificates for each of the shareholders.</li>
                <li>Register for shareholders, shares, directors etc.</li>
                <li>Company seal for the company</li>
                <li>A rubber stamp for the company</li>
              </ul>
              <p
                className="color-text mb-4"
                style={{ fontSize: "23px", marginTop: "20px" }}
              >
                Applying for Trade License, Tax Identification Number and other
                licenses
              </p>
              <p>
                After the incorporation, you should buy a commercial space or
                rent some space in any commercial area.
                <br />
                <br />
                Then you need to apply for Trade License and Tax Identification
                Number. Depending on your company’s business activities, you may
                need to obtain more business licenses.
              </p>
              <p
                className="color-text mb-4"
                style={{ fontSize: "23px", marginTop: "20px" }}
              >
                Return Filing Requirements
              </p>
              <p>
                <b>Annual Return</b>: Each calendar year, an Annual General
                Meeting must be held. The AGM must be conducted within 18 months
                of company incorporation, after which no more than 15 months can
                elapse between one AGM and the next.
                <br />
                <br />
                <b>Regular Return</b>: In case of any change in the board of
                directors or in the shareholding structure or any other change,
                a relevant return must be filed with the RJSC within a certain
                period of time.
                <br />
                <br />
                If you need professional help with company incorporation, please
                contact us.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
