import React from 'react'
import { Table } from 'react-bootstrap'

export default function TreadMarkRegistrationTable() {
  return (
    <div className='table-responsive' style={{overflowX:"auto"}}>
        <Table className="table" striped bordered hover variant="light blue">
        <thead>
          <tr style={{ textAlign: "center" }}>
            <th>Form Name</th>
            <th style={{ maxWidth: "560px" }}>Form Description</th>
            <th>Previous Fee (BDT)</th>
            <th>Rescheduled Fee (BDT)</th>
            <th>Comments</th>
          </tr>
        </thead>
        <tbody style={{ textAlign: "center" }}>
          <tr>
            <td rowSpan={3}>TM-1</td>
            <td style={{ textAlign: "start" }}>
              <div> Application for trademark registration</div>
              <small>(Textile,Non-Textile & Protective Mark)</small>
            </td>
            <td>3,500</td>
            <td>5,000</td>
            <td></td>
          </tr>
          <tr>
            <td style={{ textAlign: "start" }}>
              Application for certification mark registration
            </td>
            <td>3,500</td>
            <td>5,000</td>
            <td></td>
          </tr>
          <tr>
            <td style={{ textAlign: "start" }}>
              Application for aggregate marks registration
            </td>
            <td>3,500</td>
            <td>5,000</td>
            <td></td>
          </tr>
          <tr>
            <td>TM-2</td>
            <td style={{ textAlign: "start" }}>
              Application for registration as trademark representative
            </td>
            <td>1,500</td>
            <td>5,000</td>
            <td></td>
          </tr>
          <tr>
            <td rowSpan={2}>TM-3</td>
            <td style={{ textAlign: "start" }}>
              Application for appointment of trademark
              representative/registration/renewal/renewal with penalty/renewal
              of registration
            </td>
            <td style={{ textAlign: "start" }}>
              <div>Appointment - 2,000</div>
              <div>Renewal - 1,500</div>
              <div>Penalty - 500</div>
            </td>
            <td style={{ textAlign: "start" }}>
              <div>Appointment - 5,000</div>
              <div>Renewal - 5,000</div>
              <div>Penalty - 1,000</div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td style={{ textAlign: "start" }}>
              Application for renewal of registration of trademark
              representative
            </td>
            <td>15,000</td>
            <td>5,000</td>
            <td></td>
          </tr>
          <tr>
            <td>TM-4</td>
            <td style={{ textAlign: "start" }}>
              Application for Investigation
            </td>
            <td>1,000</td>
            <td>2,000</td>
            <td></td>
          </tr>
          <tr>
            <td>TM-5</td>
            <td style={{ textAlign: "start" }}>Application for Appeal</td>
            <td>2,000</td>
            <td>5,000</td>
            <td></td>
          </tr>
          <tr>
            <td>TM-6</td>
            <td style={{ textAlign: "start" }}>Counter Statement</td>
            <td>1,500</td>
            <td>4,000</td>
            <td></td>
          </tr>
          <tr>
            <td>TM-7</td>
            <td style={{ textAlign: "start" }}>
              Expression of Intent to Appear in Hearing / Application to Appear
              in Hearing Opposing Certification Trade Marks
            </td>
            <td>1,000</td>
            <td>2,000</td>
            <td></td>
          </tr>
          <tr>
            <td>TM-8</td>
            <td style={{ textAlign: "start" }}>
              Application for submission of regulations relating to use of
              certification trademark or collective mark
            </td>
            <td>
              1500 amendment/amendment/amendment of regulations and subsequent
              modification of said regulations or every additional regulation in
              the case of fee 600
            </td>
            <td>
              5000 amendment/amendment/amendment of regulations and subsequent
              modification of said regulations or every additional regulation in
              the case of fee 1000
            </td>
            <td></td>
          </tr>
          <tr>
            <td>TM-9</td>
            <td style={{ textAlign: "start" }}>
              Application for publication in the Journal of Notice of Acceptance
              of Applications
            </td>
            <td>1,000</td>
            <td>3,000</td>
            <td></td>
          </tr>
          <tr>
            <td>TM-10</td>
            <td style={{ textAlign: "start" }}>
              Application for inclusion, change or appointment of trademark
              representative or lawyer or change of trademark representative or
              lawyer in Bangladesh
            </td>
            <td>
              As per Stamp Act, 1899 (Act No. II of 1899) 600 for the 1st entry
              and 500 for each subsequent entry.
            </td>
            <td>
              As per Stamp Act, 1899 (Act No. II of 1899) 1000 for the 1st entry
            </td>
            <td></td>
          </tr>
          <tr>
            <td rowSpan={3}>TM-11</td>
            <td style={{ textAlign: "start" }}>
              Application for de-registration and issuance of registration
              certificate
            </td>
            <td>1,500</td>
            <td>2,000</td>
            <td></td>
          </tr>
          <tr>
            <td style={{ textAlign: "start" }}>
              (A) Additional fee for sequentially arranged series markers
            </td>
            <td>
              BDT 600 for the first mark and BDT 600 for each subsequent mark
            </td>
            <td>
              BDT 1000 for the first mark and BDT 1000 for each subsequent mark
            </td>
            <td></td>
          </tr>
          <tr>
            <td style={{ textAlign: "start" }}>
              (B) In addition to each mark in the case of associate markers
            </td>
            <td>600</td>
            <td>1,000</td>
            <td></td>
          </tr>
          <tr>
            <td>TM-12</td>
            <td style={{ textAlign: "start" }}>
              Application for renewal of trademark registration
            </td>
            <td>15,000</td>
            <td>20,000</td>
            <td></td>
          </tr>

          <tr>
            <td>TM-13</td>
            <td style={{ textAlign: "start" }}>
              Application for renewal of trademark registration
            </td>
            <td>
              21,000 (Renewal Fee - 15,000 Late Renewal Fee – 5,000
              Reinstatement Fee – 1,000)
            </td>
            <td>
              27,000 (Renewal Fee - 20,000 Late Renewal Fee – 5,000
              Reinstatement Fee – 2,000)
            </td>
            <td></td>
          </tr>

          <tr>
            <td>TM-14</td>
            <td style={{ textAlign: "start" }}>
              Application for cancellation of associated trademarks
            </td>
            <td>600</td>
            <td>1,000</td>
            <td></td>
          </tr>

          <tr>
            <td>TM-15</td>
            <td style={{ textAlign: "start" }}>
              Application to state reasons for decision
            </td>
            <td>1,500</td>
            <td>2,000</td>
            <td></td>
          </tr>

          <tr>
            <td>TM-16</td>
            <td style={{ textAlign: "start" }}>
              Application for correction of error
            </td>
            <td>1,000</td>
            <td>2,000</td>
            <td></td>
          </tr>

          <tr>
            <td>TM-17</td>
            <td style={{ textAlign: "start" }}>
              Application for renewal of trademark registration delay
            </td>
            <td>2,000 (Renewal Fee - 1,500 Forward Delay Fee 500)</td>
            <td>2,500 (Renewal Fee - 2,000 Forward Delay Fee 500)</td>
            <td></td>
          </tr>

          <tr>
            <td>TM-18</td>
            <td style={{ textAlign: "start" }}>Evidence in affidavit form</td>
            <td>Stamp Act, 1899 (Act No. II of 1899)</td>
            <td>Stamp Act, 1899 (Act No. II of 1899)</td>
            <td></td>
          </tr>

          <tr>
            <td>TM-19</td>
            <td style={{ textAlign: "start" }}>
              Application for extension of time
            </td>
            <td>1,000 per application</td>
            <td>3,000</td>
            <td></td>
          </tr>

          <tr>
            <td>TM-20</td>
            <td style={{ textAlign: "start" }}>
              Application for decision of Secretary/Registrar on validity of
              proposed assignment of trademark/certification trademark
            </td>
            <td>1,500 After per application fee 600</td>
            <td>3,000 After per application fee 1,000</td>
            <td></td>
          </tr>

          <tr>
            <td>TM-21</td>
            <td style={{ textAlign: "start" }}>
              Application for viewing copies of documents open to the public
            </td>
            <td>1,000</td>
            <td>3,000</td>
            <td></td>
          </tr>

          <tr>
            <td>TM-22</td>
            <td style={{ textAlign: "start" }}>
              Application for direction to issue notification after approval of
              assignment of property without business reputation
            </td>
            <td>1,500 After per application fee 600</td>
            <td>5,000 After per application fee 2,000</td>
            <td></td>
          </tr>

          <tr>
            <td>TM-23</td>
            <td style={{ textAlign: "start" }}>Application for hearing</td>
            <td>1,000</td>
            <td>2,000</td>
            <td></td>
          </tr>

          <tr>
            <td>TM-24</td>
            <td style={{ textAlign: "start" }}>
              Application for registration of assignment/transfer of ownership
              of trademark
            </td>
            <td>3,000 After per additional mark fee 600</td>
            <td>5,000 After per additional mark fee 1,000</td>
            <td></td>
          </tr>

          <tr>
            <td>TM-25</td>
            <td style={{ textAlign: "start" }}>
              Application for change or cancellation of registration of
              registered user
            </td>
            <td>1,500 After per application fee 600</td>
            <td>2,000 After per application fee 1,000</td>
            <td></td>
          </tr>

          <tr>
            <td>TM-26</td>
            <td style={{ textAlign: "start" }}>
              Application for registration of goods or services by persons who
              are not the registered proprietor of the trademark, imposition of
              restrictions, revocation of registration or alteration of
              registration.
            </td>
            <td>3,000</td>
            <td>10,000</td>
            <td></td>
          </tr>

          <tr>
            <td rowSpan={2}>TM-27</td>
            <td style={{ textAlign: "start" }}>
              Entry of any particulars in the register or application for
              cancellation or alteration of existing particulars by the
              registered proprietor of the trademark
            </td>
            <td>1,000</td>
            <td>3,000</td>
            <td></td>
          </tr>

          <tr>
            <td>Marker refinement</td>
            <td style={{ textAlign: "start" }}>
              Per Mark 1,500, After per application fee 1,000
            </td>
            <td>Per Mark 3,000, After per application fee 2,000</td>
            <td></td>
          </tr>

          <tr>
            <td>TM-28</td>
            <td style={{ textAlign: "start" }}>
              Application for registration as a registered user
            </td>
            <td>5,000</td>
            <td>5,000</td>
            <td></td>
          </tr>

          <tr>
            <td>TM-29</td>
            <td style={{ textAlign: "start" }}>
              Application for relinquishment or memorandum entry by the
              registered proprietor of the trademark
            </td>
            <td>1,000</td>
            <td>1,000</td>
            <td></td>
          </tr>

          <tr>
            <td>TM-30</td>
            <td style={{ textAlign: "start" }}>
              Application for entry of certificate of validity granted by court
              in register by registered proprietor of trademark
            </td>
            <td>600, for more subsequent additional registrations 200</td>
            <td>1,000, for more subsequent additional registrations 500</td>
            <td></td>
          </tr>

          <tr>
            <td>TM-31</td>
            <td style={{ textAlign: "start" }}>
              Application for any entry other than registration
            </td>
            <td>600</td>
            <td>3,000</td>
            <td></td>
          </tr>

          <tr>
            <td>TM-32</td>
            <td style={{ textAlign: "start" }}>
              Application to become a party to the proceedings
            </td>
            <td>1,000</td>
            <td>3,000</td>
            <td></td>
          </tr>

          <tr>
            <td>TM-33</td>
            <td style={{ textAlign: "start" }}>
              Application for correction of out-of-registration entry by
              registered proprietor
            </td>
            <td>1,000, After per application fee 1,000</td>
            <td>3,000, After per application fee 2,000</td>
            <td></td>
          </tr>

          <tr>
            <td>TM-34</td>
            <td style={{ textAlign: "start" }}>
              Application for Revision/Reinstatement/Interim Order of
              Registration Decision
            </td>
            <td>600</td>
            <td>5,000</td>
            <td></td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}
