import React from 'react'
import { Link } from 'react-router-dom'
import Faq from '../../../components/Faq'
import Meta from '../../../components/Meta'
import accountImg from "../../../assets/images/Trade-mark-Registration.png"
import account_og_img from "../../../assets/og-img/Account-service.png"
import Marquee from 'react-fast-marquee';
import { serviceRelatedArticales } from '../../../data/relatedArticale'

function AccountService() {
  const faqItems = [
    {
      title: 'What is client account services?',
      content: <strong>The term client accounting services (CAS) refers to completely outsourced accounting work meant to fulfill your clients' accounting needs. If you offer this service,
        you handle the complete accounting cycle for your clients and act as their outsourced accounting department.</strong>
    },
    {
      title: 'What is account services do?',
      content: <strong>Account services is a department of employees at a company whose primary duties relate to managing customer financial accounts and other customer records within the company.
        These professionals generally work in an office environment, although some may also work remotely from home.</strong>
    },
    {
      title: 'What are payment account services?',
      content: <strong>A payment account is a bank account that allows you to make daily payment transactions. These transactions can include depositing funds, making cash withdrawals and card transactions.
        Accounts that don't allow you to make these types of transactions are known as non-payment accounts.</strong>
    }
  ];
  return (
    <>
      <Meta title="Account Service"
        description="This page provides information about BSTI permission and related processes."
        image={account_og_img}
        keywords="keyword1, keyword2, keyword3"
      />
      
             

      <div className="container-fluid hero-header bg-light py-0">
        <div className="container py-5">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <h1 className="display-4 mb-3 animated slideInDown">Account Service</h1>
              <nav aria-label="breadcrumb animated slideInDown">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#">Taxation</Link></li>
                  <li className="breadcrumb-item"><Link to="#">Audit</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">Account Service</li>
                </ol>
              </nav>
            </div>
            <div className="col-lg-6 animated fadeIn">
              <img className="img-fluid animated pulse infinite" style={{ animationDuration: '3s' }} src={accountImg} alt='Trade-mark-Registration' />
            </div>
          </div>
        </div>
      </div>

      <div className='container-xxl center-background1'>
        <h2> Related Articles</h2>
      </div>

      <div className='container-xxl center-background1'>
        <Marquee className="marquee-container">
        {
          serviceRelatedArticales
        }
        </Marquee>
      </div>

      <div className="container py-5 my-0">
        <h2 className="color-text text-center mb-4">Account Service FAQ</h2>
        <Faq items={faqItems} />
      </div>

             
    </>
  )
}

export default AccountService