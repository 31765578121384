import React from "react";
import company_Formation_og_img from "../assets/og-img/company-formation.png";
import Meta from "./Meta";
export default function BranchOfficeInBd() {
  return (
    <>
      <Meta
        title="Company Formation in Bangladesh | Company Registration"
        description="Company Formation or Registration in Bangladesh. A private limited company in Bangladesh has a separate legal entity"
        image={company_Formation_og_img}
        keywords="Company Formation, Company Registration, Company Registration Bangladesh, private limited company"
      />
      <div className="container-xxl py-5">
        <div className="container">
          <h1 style={{ marginBottom: "20px" }} className="color-text display-6">
            Liaison or branch or project office in Bangladesh
          </h1>
          <p>
            Branch, Liaison or Project Office is one of the two options for
            foreign investors wishing to setup their presence in Bangladesh. The
            other option is incorporating a Bangladeshi subsidiary company.
            <br />
            <br />
            Branch, Liaison or Project Office are often established by companies
            as a short-term and temporary setup. They are typically extensions
            of the parent company and are not separate legal entities. They are
            often used for specific projects or to explore a new market. On the
            other hand, while a limited company is a more permanent business
            structure with more flexibility and protection for its shareholders.
            <br />
            <br />
            If you are a foreign investor, we recommend you to read our article
            on foreign investment. It will help you to understand the legal
            regime about foreign investment in Bangladesh.
            <br />
            <br />
            In the subsequent sections, we’ll discuss the intricacies of Branch,
            Liaison or Project Office setup, outlining the steps involved,
            necessary documentation, and the regulatory landscape to ensure a
            smooth and compliant establishment of your business presence in
            Bangladesh.
            <br />
            <br />
            Key Considerations for Liaison, Branch and Project Office
          </p>
          <h2
            style={{ marginBottom: "20px", fontSize: "30px" }}
            className="color-text display-6"
          >
            Liaison Office
          </h2>
          <ul style={{ color: "#5c5a5a" }}>
            <li>
              Liaison office and Representative office are same in Bangladesh.
            </li>
            <li>
              A Liaison/Representative Office of a foreign Company can perform
              very limited activities, which includes:
              <ol style={{ color: "#5c5a5a" }}>
                <li>
                  maintain liaison/ coordination between principal and local
                  agents, distributors/ exporters’ institutions through
                  correspondences, personal contracts and other electronic
                  media.
                </li>
                <li>
                  collect, compile analyze and disseminate business information
                  related to its field of activities as mentioned in the
                  approval letter.
                </li>
              </ol>
            </li>
            <li>
              A Liaison/Representative office will have no local source of
              income in Bangladesh. All set-up and operational costs including
              salaries of the expatriates and local employees of the Liaison or
              Representative office will have to borne by the parent company
              aboard.
            </li>
            <li>
              No outward remittances of any kind from Bangladesh sources will be
              allowed except the amount brought in from abroad (the unspent
              part).
            </li>
          </ul>
          <div>
            <h2
              style={{ marginBottom: "20px", fontSize: "30px" }}
              className="color-text display-6"
            >
              Project Office
            </h2>
            <ul style={{ color: "#5c5a5a" }}>
              <li>
                BIDA introduces this new option in {new Date().getFullYear()}.
              </li>
              <li>
                A project office is supposed to be established for the
                implementation of activities of government, foreign development
                cooperatives or public and private funded development projects
                or parts thereof in Bangladesh.
              </li>
              <li>
                For the approval of the project office, a recommendation of the
                implementing agency will be required.
              </li>
            </ul>
          </div>
          <div>
            <h2
              style={{ marginBottom: "20px", fontSize: "30px" }}
              className="color-text display-6"
            >
              Branch, Liaison or Project Office (The “Office”)
            </h2>
            <ul style={{ color: "#5c5a5a" }}>
              <li>
                The duration of a Branch and Liaison and Project office shall
                remain confined to the period of time mentioned in the approval
                letter issued by BIDA. If a company intends to continue it
                Office beyond the period approval, they will have to apply for
                necessary renewal/ extension in the prescribed form with proper
                documents at least 2 (two) months before the expiry of the
                current term.
              </li>
              <li>
                The activities of a Branch and Liaison and Project office shall
                remain confined to the areas approved in the approval letter
                issued by BIDA. If a company intends to expand the activities
                beyond the approved list, they will have to apply for amendment
                for adding the new activities.
              </li>
              <li>
                If the company intends to employ any foreign nationals(s) in
                their Office, work permits should be obtained from the BIDA.
              </li>
              <li>
                An amount of foreign exchange equivalent to the sum of not less
                than US$ 50,000 or equivalent must be brought in as inward
                remittance in Bangladesh within 02(two) months from the date of
                issuance of the BIDA permission letter. For this purpose, the
                Office shall have to open an account with any scheduled bank of
                Bangladesh as per the instructions contained in the Guidelines
                for Foreign Exchange transactions for receipt of remittances.
              </li>
              <li>
                Quarterly return of incomes and expenditures out of remittances
                received from abroad shall have to be submitted to the BIDA,
                concerned Bank, National Board of Revenue and Bangladesh Bank
                with documentary evidence.
              </li>
              <li>
                Any change(s) of present address shall be intimated to the BIDA
                for necessary action and approval before the proposed change is
                carried out.
              </li>
              <li>
                The permitted Office shall have to obtain clearances/ licenses
                from the concerned government agencies, as and where required,
                under the existing rules of the country.
              </li>
              <li>
                The permitted Office shall have to pay duty/ income tax/VAT/
                revenues and other taxes payable to the government under the
                existing laws of Bangladesh.
              </li>
              <li>
                Deduction of taxes at source while paying office/house rent,
                salaries, and bills for purchased goods, services and contract
                work has to be made, and subsequently deposited to the
                designated government accounts as per provisions of Bangladesh
                Income Tax Act 2023. For the purpose of deduction of taxes at
                source & VAT, enterprises shall have to obtain Taxpayer
                Identification Number (TIN) and Business Identification Number
                (BIN) from the National Board of Revenue.
              </li>
              <li>
                Approval of the Offices and work permit for foreign nationals
                will be made on the condition of security clearance from the
                Ministry of Home Affairs. Unless the security clearance is
                issued, the renewal of the Office and work permit will not be
                renewed.
              </li>
              <li>
                Expatriates working in the Offices must pay income tax, submit
                income tax return in Bangladesh. Later income tax clearance
                certificate must be submitted when cancelling work permit.
              </li>
              <li>
                Branch and Liaison Office under proprietorship companies is
                generally discouraged.
              </li>
              <li>
                For establishing more than one Office of the permitted Branch or
                Liaison Offices in Bangladesh, the foreign company shall have to
                take separate prior permission from the Bangladesh Investment
                Development Authority.
              </li>
            </ul>
          </div>
          <div>
            <h2
              style={{ marginBottom: "20px", fontSize: "30px" }}
              className="color-text display-6"
            >
              Other Information
            </h2>
            <p>
              <b> Name:</b> The name of Bangladeshi Branch or Liaison or Project
              Office must correspond to the name of the foreign company.
            </p>
            <p>
              <b>Constitution and Activities:</b> The shareholders, structure of
              company and its activities are directed by foreign company’s
              Memorandum and Articles of Association. There is no separate
              Memorandum and Articles of Association for the Office.
            </p>
            <p>
              <b>Office Address:</b> An Office must have an office address
              located in Bangladesh.
            </p>
            <p>
              <b>Directors and Shareholders:</b> The Branch or Liaison or
              Project Office will not have any separate Board of Directors and
              Shareholders. The Directors and shareholders of the Company will
              be considered as the shareholders and Directors of the Office.
            </p>
            <p>
              <b>Government Fees:</b> A fee of BDT 25.000 (Taka Twenty-five
              thousand) is required to be paid through bank Pay Order and the
              original copy of the Pay Order has to be submitted to the BIDA.
              15% VAT will be added to the fees.
            </p>
          </div>
          <div>
            <h2
              style={{ marginBottom: "20px", fontSize: "30px" }}
              className="color-text display-6"
            >
              Documents Required
            </h2>
            <p>
              In general, the following documents/information is required for
              approval of a Branch, Liaison or Project Office in Bangladesh:
            </p>
            <ul style={{ color: "#5c5a5a" }}>
              <li>
                Principal Company’s Board of Director’s resolution regarding the
                opening of office in Bangladesh.
              </li>
              <li>
                Audited Accounts of the last financial year of the principal
                company.
              </li>
              <li>
                Proposed organogram of the office showing the posts to be
                occupied by both the expatriates and local personnels.
              </li>
              <li>Detailed activities of the principal company.</li>
              <li>
                Name and nationality of the Directors/owners of the principal
                company.
              </li>
              <li>
                Memorandum and Articles of Association of the principal company
                and Certificate of Incorporation.
              </li>
              <li>
                Detail of activities to be performed through the proposed
                branch/liaison/representative office in Bangladesh and future
                plan in industrial revolution.
              </li>
              <li>
                Details of Project /copy of Agreement with EPC Contractor in
                case of project office.
              </li>
              <li>Forwarding (i.e. Cover) Letter.</li>
              <li>
                Letter of authorization signed by the management of the company
                for submitting the application.
              </li>
              <li>Additional information with proper documents (if any).</li>
            </ul>
            <p>
              <b>Special Note:</b>
            </p>
            <ul style={{ color: "#5c5a5a" }}>
              <li>
                Documents shall have to be attested by the concern Bangladesh
                mission/mission of the respective country in
                Bangladesh/respective country’s apex business chamber/local
                business chambers.
              </li>
              <li>
                Any documents not in English must be translated in English
                before submission.
              </li>
              <li>
                4 copies of all the documents should be submitted to BIDA.
              </li>
              <li>
                BIDA might ask for more documents after reviewing the
                above-mentioned documents.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h2
            style={{ marginBottom: "20px", fontSize: "30px" }}
            className="color-text display-6"
          >
            Registration Procedure and Timeline
          </h2>
          <p>
            The process is now online. All documents should be uploaded to
            BIDA’s online portal and later copies of the documents should be
            submitted physically. After proper scrutiny of all documents, BIDA
            officials will place the application and documents to
            Inter-Ministerial Committee. The Committee will review the documents
            and might ask for more documents or a physical presentation. If the
            committee is satisfied, they will provide the approval. The
            Inter-Ministerial Committee usually sits twice every month. Usual
            timeline is one month or less.
            <br />
            <br />
            Approval is usually provided to open a branch or liaison office
            initially for a period of 3(three) years which can be extended
            later.
          </p>
        </div>
        <div>
          <h2
            style={{ marginBottom: "20px", fontSize: "30px" }}
            className="color-text display-6"
          >
            Bank Account Opening and US$ 50,000
          </h2>
          <p>
            After obtaining the approval for a Branch or Liaison office, a bank
            account must be opened in any bank in Bangladesh. An amount of
            foreign exchange equivalent to US$ 50,000 or more must be brought in
            as inward remittance in Bangladesh within 02(two) months from the
            date of issuance of the BIDA permission letter.
          </p>
        </div>
        <div>
          <h2
            style={{ marginBottom: "20px", fontSize: "30px" }}
            className="color-text display-6"
          >
            Notification to Bangladesh Bank
          </h2>
          <p>
            Afterwards, the newly approved Branch Office or Liaison Office or
            Representative Office will have to report to Bangladesh Bank within
            30(thirty) days of obtaining permission from BIDA. Bangladesh Bank
            does not allow Branch Office or Liaison Office or Representative
            Office to directly submit this report to them. Rather, it should be
            done via the bank of the Branch Office or Liaison Office or
            Representative Office. As such, the newly approved Branch Office or
            Liaison Office or Representative Office must open a bank account
            without any delay after getting the approval from BIDA.
          </p>
        </div>
        <div>
          <h2
            style={{ marginBottom: "20px", fontSize: "30px" }}
            className="color-text display-6"
          >
            Registration with Registrar of Joint Stock Companies and Firm (RJSC)
          </h2>
          <p>
            After obtaining approval from both Bangladesh Bank and BIDA,
            registration with the RJSC is required. The following documents will
            be required for this purpose:
          </p>
          <ol style={{ color: "#5c5a5a" }}>
            <li>Approval letter of BIDA.</li>
            <li>Approval letter of Bangladesh Bank. </li>
            <li>
              Certified copy of the memorandum and articles of the company or
              other constitutional documents.{" "}
            </li>
            <li>
              Full address of the registered or principal office of the company.
            </li>
            <li>
              A list of the directors and secretary, if any, of the company.
            </li>
            <li>
              The name and address or the names and addresses of one or more
              persons resident in Bangladesh, authorized to accept on behalf of
              the company service of process and any notice or other document
              required to be served on the company.
            </li>
            <li>
              The full address of the office of the company in Ballades which to
              be deemed its principal place of business in Bangladesh.
            </li>
          </ol>
          <p>
            If you need professional help with branch office or liaison office
            or with foreign investment, please contact us.
          </p>
        </div>
      </div>
    </>
  );
}
