import React from 'react'
import { Link } from 'react-router-dom'
import Faq from '../../components/Faq';
import Meta from '../../components/Meta';
import BankLoan from '../../assets/images/Bank-loan.png'
import BankLoanprocessImg from '../../assets/images/Bank-Loan-process.png'
import WhyBusinessLoanImg from '../../assets/images/Why-Business-Loan.png'
import  loan_og_img from "../../assets/og-img/bank-loan.png"
import Marquee from 'react-fast-marquee';
import { startupRelatedArticales } from '../../data/relatedArticale';

function LoanConsultancy() {
    const faqItems = [
        {
            title: 'What is a loan consultant?',
            content: <strong>Loan consultants are the people who help you decide which mortgage, auto loan
            or other type of loan is right for you.</strong>
        },
        {
            title: 'How can I get an international loan in Bangladesh?',
            content: <strong>The application for foreign loan has to be submitted to BIDA in the prescribed form.</strong>
        },
        {
            title: 'How do consultants get paid?',
            content: <strong>Some consultants prefer an hourly rate, while others charge per project or based
            on a retainer.</strong>
        }
    ];
    return (
        <>
           <Meta title="Loan Consultancy"
                description="This page provides information about BSTI permission and related processes."
                image={loan_og_img}
                keywords="keyword1, keyword2, keyword3"
           />
                   

            <div className="container-fluid hero-header bg-light py-5">
                <div className="container py-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <h1 className="display-4 mb-3 animated slideInDown">Business Loan</h1>
                            <nav aria-label="breadcrumb animated slideInDown">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Startup</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Loan Consultancy</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-6 animated fadeIn">
                            <img className="img-fluid animated pulse infinite" style={{ animationDuration: '3s' }} src={BankLoan} alt='Bank-loan' />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <p>
                        A business loan can help you pay for the things need when you don't have the cash, but
                        borrowing money can be complicated. Starting the bank loan application process without
                        understanding the ins and outs can cause your loan request to be rejected. Learn what to
                        expect and what you can do ahead of time to increase the odds of getting approved.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>Understand Your Credit</b>
                    </p>
                    <p>
                        You generally need credit to get a bank loan. In addition, your credit will often dictate the
                        type of loan and loan terms a lender grants you. This means that you should have a history of
                        borrowing and repaying loans to get a loan. How do you get a loan when you need money if you
                        don’t have credit?<br></br><br></br>
                        You have to start somewhere, and that generally means borrowing less money at higher interest
                        rates. You can also consider alternative lenders such as online lenders, who are often willing
                        to look at aspects of your financial record beyond your credit when deciding whether to grant
                        you a loan. Once you develop a strong credit history, lenders will lend you more and at lower
                        interest rates.<br></br><br></br>
                        You can view your credit for free you get one free report per year from all three major credit
                        reporting agencies: AAA, Trans Union, and Equifax. Review your credit history to see what
                        lenders will see when you ask for a loan. If there’s not much in there, it will generally be
                        harder to get a loan because lenders can't assess your risk as a borrower. This means you may
                        need to build credit before you obtain a loan by gradually adding loans to your history.<br></br><br></br>
                        Be sure to fix any mistakes in your credit files, as they make you a risky borrower in the eyes
                        of lenders and hurt your chances of getting a good Business Loan.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>Decide on a Bank Loan Amount</b>
                    </p>
                    <p>
                        Remember that a loan isn't free money you will eventually have to pay the borrowed amount
                        plus interest back to a bank or other lender. If you don't make loan payments on time, your
                        credit score could drop. This is why it's important to settle on the right borrowing amount.
                        <br></br><br></br>
                        Consider the amount you need based on what you plan to do with the money. But also factor in
                        what your regular loan payments might be and whether you can keep up with them according to
                        the loan repayment period, be it monthly or quarterly.<br></br><br></br>
                        It’s also a good idea to run preliminary loan calculations before settling on a Business Loan
                        amount. This allows you to see how much you’ll pay for a loan of a certain amount, and how a
                        different loan amount (or loan term, or interest rate) might save you money. There are plenty
                        of online tools out there to help you calculate loans. Of course, loan rates and lender terms
                        can make your final loan installments slightly different.
                    </p>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6  "  >
                            <img className="img-fluid" src={BankLoanprocessImg} alt='Bank-Loan-process' />
                        </div>
                        <div className="col-lg-6  "  >
                            <div className="h-100">
                                <p className="color-text fs-5 mb-4">Determine the Type of Bank Loan You Need </p>
                                <p>

                                    <p>
                                        Next, figure out what type of bank loan you need. The type of loan you get will
                                        depend on what you plan to do with the money. Some common loan types include:
                                    </p>

                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Auto loans for buying a vehicle
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Home loans (mortgage loans), including second mortgages for buying a home or
                                            borrowing against the equity in your home
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-4">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Personal loans, which can be used for almost any purpose
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-4">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Business loans for starting or expanding your business
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-4">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Student loans for educational purposes
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-4">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Fast loans, which can provide quick cash for emergencies
                                        </span>
                                    </div>
                                    <p>
                                        Some lenders may let you take out a certain type of loan that does not match your
                                        specific need for a loan. For example, you can generally take out a personal loan
                                        to pay for health, home repair, or other expenses. However, other loan types must
                                        be used for a specific purpose. For example, you generally have to use a mortgage
                                        loan to buy a home. In addition, you may not be eligible for all types of loans.
                                        To get a student loan, for example, you usually have to provide proof of
                                        enrollment in a degree program.
                                    </p>
                                    <p className="color-text fs-5 mb-4">Decide Where to Borrow Money</p>
                                    <p>
                                        Once you have an idea of your credit, loan type, and loan amount, shop around for
                                        a lender. The Balance provides lists of the best mortgage lenders and personal
                                        loan companies so that you can compare individual lenders.<br></br><br></br>
                                        Again, the type of loan you want may dictate your choice of a lender. Some
                                        institutions don’t offer business loans or student loans. Start your search at
                                        the institutions best known for making affordable loans of the type you want. For
                                        example, go through your school’s Student Aid office for an education loan before
                                        you go to the bank for a private student loan.<br></br><br></br>
                                        Banks and credit unions are a good place to shop for most loans. Check with
                                        several institutions and compare interest rates and costs. Peer-to-peer loans and
                                        other sources of marketplace lending should also be on your list. Online lenders
                                        provide another option but stick to reputable sites if you go this route.
                                    </p>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-0">
                <div className="container">
                    <p>
                        Some people borrow money from private lenders, such as friends or family. While that can make
                        approval easier and keep costs low, it can also cause problems. Make sure you put everything
                        in writing so everybody’s on the same page—money can ruin relationships, even if the dollar
                        amounts are small.<br></br><br></br>
                        Avoid high-cost loans and predatory lenders, who will often dupe you into a loan you don't
                        qualify for or can't afford. It’s tempting to take whatever you can get when you’ve been
                        turned down repeatedly and don’t know how else to get a loan for the money you need. However,
                        it’s not worth it they’ll lend you money, but you’ll find yourself in a hole that’s difficult
                        or impossible to get out of.<br></br><br></br>
                        Payday loans, which are high-interest short-term loans, tend to be the most expensive options.
                        Likewise, loan sharks, who impose loan repayment terms that are virtually impossible to meet,
                        can be outright dangerous.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>Understand Your Credit</b>
                    </p>

                    <img className="mx-auto responsive-img" src={WhyBusinessLoanImg} alt='Why-Business-Loan' style={{ width: 1400, height: 300 }} />

                    <p>
                        Before you get a bank loan, take a look at how the loan works. How will you repay it monthly
                        or all at once? What are the interest costs? Do you have to repay a certain way (perhaps the
                        lender requires you to pay electronically through your bank account)? Make sure you understand
                        what you’re getting into and how everything will work before you borrow money. It's also wise
                        to plug the loan terms into a loan calculator again and view an amortization table (whether
                        you build it yourself or let a computer do it for you) so that you can budget for the loan
                        and see how it will get paid off over time.<br></br><br></br>
                        Get a loan that you can really handle one that you can comfortably repay and that won’t
                        prevent you from doing other important things (like saving for retirement or having a little
                        fun). Figure out how much of your income will go towards loan repayment lenders call this a
                        debt to income ratio and borrow less money if you don’t like what you see. Many lenders want
                        to see a ratio below 36% or so.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>Apply for the Business loan</b>
                    </p>
                    <p>
                        You’re ready to get your bank loan once you’ve:
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-4">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Spruced up your credit
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Settled on a loan amount
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Picked the best type of loan
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Shopped the competition
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Run the numbers
                            </span>
                        </div>
                        <p>
                            At this point, you can go to your chosen lender and apply. The process is easy to start:
                            Simply tell the lender you want to borrow money, and tell them what you’re going to do
                            with the funds (if required). They will explain the next steps and how long the process
                            will take.<br></br><br></br>
                            When filling out an application, you'll provide information about yourself and your finances.
                            For example, you'll need to bring identification, provide an address and social security
                            number (or equivalent), and supply information about your income.
                        </p>
                    </p>

                    <p className="color-text fs-5 mb-4">
                        <b>Go Through Underwriting</b>
                    </p>
                    <p>
                        After you submit the bank loan application, the lender will evaluate you as a potential
                        borrower. This process may be instant, or it may take a few weeks. For example, home loans
                        take longer than credit card offers because there’s more at stake. Mortgage loans require
                        extensive documentation, such as bank statements and pay stubs to prove that you have the
                        ability to repay. You can make the process easier on yourself by getting everything in order
                        several months before you apply.<br></br><br></br>
                        During underwriting, lenders will pull your credit (or just use a credit score) and review
                        your application. They may call you occasionally and ask you to clarify or prove something.
                        Be sure to comply with these requests in a timely manner to avoid prolonging the loan
                        application process.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>Business Loans</b>
                    </p>
                    <p>
                        Business loans are similar to any other kind of bank loan. Lenders look for the same basic
                        attributes in business borrowers as they do in people seeking personal loans. However, new
                        businesses don’t have a long borrowing history (or credit). In addition, new enterprises and
                        service businesses typically don't own assets that can be pledged as collateral, so they have
                        to work a little harder to get loans.<br></br><br></br>
                        Lenders often look at the borrower's personal credit and income to determine whether they
                        qualify for a business loan. They may also look at the credit of the business. Business owners
                        who don't have sufficient business credit may also have to pledge personal assets as collateral
                        to get loans. This is often the only way to get loans in the early years, but you should try
                        to build business credit so you can eventually borrow without risking personal assets.<br></br><br></br>
                        Bangladesh is becoming one of the most promising outbound markets in the world. Due to the
                        swift growth of the economy in this country, the company formation procedure is broadly asking
                        questions to the new entrepreneurs. All the questions are answered above how you should
                        register your company.
                    </p>
                </div>
            </div>

            <div className='container-xxl center-background1'>
                <h2> Related Articles</h2>
            </div>
            
            <div className='container-xxl center-background1'>
            <Marquee className="marquee-container">
               {
                startupRelatedArticales
               }               
            </Marquee>
            </div>

            <div className="container py-5 my-0">
                <h2 className="color-text text-center mb-4">Loan Consultancy FAQ</h2>
                <Faq items={faqItems} />
            </div>

                   
        </>
    )
}

export default LoanConsultancy