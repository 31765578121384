import React from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'react-bootstrap'
import Meta from '../../components/Meta'
import Faq from '../../components/Faq'
import ImportRegistrationImg from '../../assets/images/Import-Registration.png'
import IRCcertificateprocessiMG from '../../assets/images/IRC-certificate-process.png'
import RequiredDocumentsRENEWALOFIRCImg from '../../assets/images/Required-Documents-RENEWAL-OF-IRC.png'
import imprort_og_img from "../../assets/og-img/Import Registration Certificate.png"
import Marquee from 'react-fast-marquee';
import { startupRelatedArticales } from '../../data/relatedArticale'

function ImportLicense() {
    const faqItems = [
        {
            title: 'How can I get Import Licence in Bangladesh?',
            content: <strong>PROCESS STEPS</strong>
        },
        {
            title: 'What is IRC and ERC in Bangladesh?',
            content: <strong>Now an importer having Import Registration Certificate (IRC) and an Exporter
            having Export Registration Certificate (ERC) can Import &amp; Export any permissible item
            without any value and quantity restrictions and without obtaining any permission from
            any authority.</strong>
        },
        {
            title: 'How do I apply for IRC?',
            content: <strong>documents have to be submitted with application of ad-hoc and regular IRC</strong>
        }
    ];
    return (
        <>
            <Meta title="Import License Registration in Bangladesh 2024 | Import Business License"
                  description="A import business required import registration license in Bangladesh from ccei. The Import Business license can import anything and Indenting Business from aboard"
                  image={imprort_og_img}
                  keywords="Import License Registration, Import Business License, Indenting Business"
            />
                   

            <div className="container-fluid hero-header bg-light py-5">
                <div className="container py-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <h1 className="display-4 mb-3 animated slideInDown">Import License</h1>
                            <nav aria-label="breadcrumb animated slideInDown">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Startup</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Import License</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-6 animated fadeIn">
                            <img className="img-fluid animated pulse infinite" style={{ animationDuration: '3s' }} src={ImportRegistrationImg} alt='Import-Registration' />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <p>
                        Import License permissible item without any value and quantity restrictions and without
                        obtaining any permission from any authority in Bangladesh but the Import Registration
                        Certificate (IRC) or import license is needed for the import of the same. In Bangladesh,
                        the Office of the Chief Controller of Imports and Exports.<br></br><br></br>
                        (CCIE) which is an individual organization under the Ministry of Commerce issues this import
                        certificate or import license, and the legal basis for this permission is found in Import
                        Policy Order 2015-2018.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>IMPORT REGISTRATION CERTIFICATE (IRC)</b>
                    </p>
                    <p>
                        <b>REQUIRED SUPPORTING DOCUMENTS:</b>
                    </p>

                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                NID
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Tax Identification Number (eTIN)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Picture
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Passport - Managing Director (If, 100% foreign equity limited company)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                BIDA Registration Certificate (If, 100% foreign equity limited company)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Bank Encasement Certificate (If, 100% foreign equity limited company)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Copy of valid Trade License ( Must be mention Import as a business category )
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Tax Acknowledgement Slip/ Income Tax Certificate
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                VAT Registration Certificate
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Bank Solvency Certificate
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Copy of membership certificate from local Chamber of Commerce and Industry or relevant Trade Association
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Original copy of Treasury Callan (Now, Online Payment)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                If a partnership business: Partnership Deed (certified attested copy)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                If a limited company: (certified attested copies)
                            </span>
                        </div>
                        <span className='px-5'>1. Certificate of Incorporation</span><br />
                        <span className='px-5'>2. Articles of Association</span><br />
                        <span className='px-5'>3. Memorandum of Association</span>
                        <p>
                            (To facilitate verification of the submitted documents, applicants are requested to submit the
                            original documents as well. The original copies of the documents will be returned at the
                            completion of the process).
                        </p>
                    </p>
                </div>
            </div>

            <div className="container-xxl py-0">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6  "  >
                            <img className="img-fluid" src={IRCcertificateprocessiMG} alt='IRC-certificate-process' />
                        </div>
                        <div className="col-lg-6  "  >
                            <div className="h-100">
                                <p className="color-text fs-5 mb-4">PROCESS STEPS </p>
                                <p className="color-text fs-5 mb-4">ONLINE APPLICATION </p>
                                <p>
                                    Apply for the online application for export import licence with CCIE login. Export
                                    Import licence online apply. Import Permit application Bangladesh
                                </p>
                                <p className="color-text fs-5 mb-4">APPLICATION FILING</p>
                                <p>
                                    Submit the online application along with the prescribed fee and to the Chief
                                    Controller of Imports & Exports (CCIE). Anyone can file the application for
                                    obtaining the Import Registration Certificate (IRC) which is well known as
                                    Import License.
                                </p>
                                <p className="color-text fs-5 mb-4">DEPOSIT SCHEDULE FEES</p>
                                <p>
                                    Deposit schedule fees as per import the basis of their value ceiling of overall
                                    annual import by using online banking or sonali pay.
                                </p>
                                <p className="color-text fs-5 mb-4">RECEIVED CERTIFICATE</p>
                                <p>
                                    Firstly, After formalities checking the submitted documents, the office of the
                                    Chief Controller of Imports & Exports will issue the license in favor of the
                                    applicant entity.<br></br><br></br>
                                    Secondly, Anyone can Import any permissible item without any value and quantity
                                    restrictions and without obtaining any permission from any authority in Bangladesh
                                    but the Import Registration Certificate (IRC) or import license is needed for the
                                    import of the same.<br></br><br></br>
                                    Thirdly, In Bangladesh, the Office of the Chief Controller of Imports and Exports
                                    (CCIE) which is an individual organization under the Ministry of Commerce issues
                                    this import certificate or import license, and the legal basis for this permission
                                    is found in The Import Policy Order 2021-24.<br></br><br></br>
                                    So finally, Import Registration Certificate (IRC) or import license is a mandatory
                                    document for business entities who are desirous of importing raw material or any
                                    other special items to Bangladesh.
                                </p>
                                <p className="color-text fs-5 mb-4">RENEWAL OF IRC</p>
                                <p>
                                    IRC is required to be renewed every year. The required documents are:
                                </p>
                                <p>
                                    <div className="d-flex align-items-center mb-1">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            IRC and Last year IRC Certificate (soft copy)
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-1">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Renewal Application by the applicant
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-1">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Treasury Challan of the annual renewal fee at Bangladesh Bank or Sonali Bank Pay, Online banking ( Scan Copy)
                                        </span>
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-0">
                <div className="container">
                    <p>
                        The applicant is required to arrange the below-mentioned documents and deposit the annual
                        renewal fee at Bangladesh Bank or Sonali Bank<br></br><br></br>
                        <b>Note:</b> Renewal of IRC can also be done by the Nominated Banks as per the Import Policy Order
                        2021-24. The IRC applicant needs to mention the name of a single Nominated bank in the IRC
                        application form for subsequent renewals.
                    </p>
                    <img className="mx-auto responsive-img" src={RequiredDocumentsRENEWALOFIRCImg} alt='Required-Documents-RENEWAL-OF-IRC' style={{ width: 1400, height: 300 }} />
                    <p className="color-text fs-5 mb-4">
                        <b>IRC REGISTRATION FEES</b>
                    </p>
                    <p>
                        The importers have been classified into 6 (six) categories on the basis of their value ceiling
                        of overall annual import. The initial category-wise registration fees and annual IRC renewal
                        fees in Bangladesh are as under:
                    </p>
                    <Table striped bordered hover variant="light blue" >
                        <thead>
                            <tr>
                                <th>Category</th>
                                <th>Value Ceiling of annual import</th>
                                <th>Initial Registration fees</th>
                                <th>IRC renewal fees in Bangladesh</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Tk. 5,00,000</td>
                                <td>Tk. 5,000</td>
                                <td>Tk. 3,000</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Tk. 25,00,000</td>
                                <td>Tk. 10,000</td>
                                <td>Tk. 6,000</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Tk. 50,00,000</td>
                                <td>Tk. 24,000</td>
                                <td>Tk. 10,000</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Tk. 1,00,00,000</td>
                                <td>Tk. 40,000</td>
                                <td>Tk. 15,000</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>Tk. 5,00,00,000</td>
                                <td>Tk. 50,000</td>
                                <td>Tk. 22,000</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>Tk. 20,00,00,000</td>
                                <td>Tk. 60,000</td>
                                <td>Tk. 24,000</td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>Tk. 50,00,00,000</td>
                                <td>Tk. 70,000</td>
                                <td>Tk. 28,000</td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>Above Tk. 100,00,00,000</td>
                                <td>Tk. 80,000</td>
                                <td>Tk. 32,000</td>
                            </tr>
                            <tr>
                                <td colSpan={4}><b><center>Grap: Import licence fee in Bangladesh</center></b></td>
                            </tr>
                            <tr>
                                <td colSpan={4}><center><b>Note:</b> Initial Registration fees and Annual renewal fees are excluded VAT. 15% VAT Applicable.</center></td>
                            </tr>
                        </tbody>
                    </Table>
                    <p className="color-text fs-5 mb-4">
                        <b>REQUIRED DOCUMENTS FOR REGISTRATION OR RENEWAL</b>
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Photo of the applicant/owner/proprietor/managing director (Scan Copy)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                NID of the applicant/owner/proprietor/managing director (Scan Copy)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Up to date Trade license (Scan Copy)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                E-TIN (Scan Copy)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                VAT Registration Certificate
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Up to date membership certificate (Scan Copy)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Up to date Bank Solvency Certificate (Scan Copy)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                MOA (in case of a company)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Form XII MOA (in case of a company)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Incorporation Certificate MOA (in case of company)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Partnership deed (in case of Partnership Firm)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Environment Certificate(in case of a factory)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Company PAD
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Mobile & e-mail address.
                            </span>
                        </div>
                        <p>
                            As Bangladesh is becoming one of the most promising outbound markets in the world. Due to
                            swift growth of economy in this country, company formation procedure is broadly asking
                            question to the new entrepreneurs. All the questions are answered above how you should
                            register your company.
                        </p>
                    </p>
                </div>
            </div>

            <div className='container-xxl center-background1'>
                <h2> Related Articles</h2>
            </div>
            
            <div className='container-xxl center-background1'>
            <Marquee className="marquee-container">
             {
                startupRelatedArticales
             }               
            </Marquee>
            </div>

            <div className="container py-5 my-0">
                <h2 className="color-text text-center mb-4">Import License FAQ</h2>
                <Faq items={faqItems} />
            </div>

                   
        </>
    )
}

export default ImportLicense