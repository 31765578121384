import React from "react";
import Meta from "./Meta";
import company_Formation_og_img from "../assets/og-img/company-formation.png";
import tunelImg from "../assets/images/tunel.jpg";
import bepzaImg from "../assets/images/bepza.jpg";
import padmaBridgeImg from "../assets/images/padma-bridge.jpg";
import stepOfBusinessRegister from "../assets/images/company-formations-steps.jpg";

export default function BusinessSetupInBD() {
  return (
    <>
      <Meta
        title="Company Formation in Bangladesh | Company Registration"
        description="Company Formation or Registration in Bangladesh. A private limited company in Bangladesh has a separate legal entity"
        image={company_Formation_og_img}
        keywords="Company Formation, Company Registration, Company Registration Bangladesh, private limited company"
      />
     
      <div className="container-xxl py-5">
        <div className="container">
        <div
        class="col"
        style={{
          backgroundColor: "#2e5f71",
          padding: "31px",
          margin: "50px 10px",
        }}
      >
        <h1
          style={{ marginBottom: "20px", color: "white" }}
          className="display-6"
        >
          Business Setup in Bangladesh
        </h1>
        <p style={{ lineHeight: "31px", color: "white" }}>
          The Company Formation or company registration relocation of the
          existing company of Bangladesh and also note that most of the
          Bangladeshi companies are registered as private limited companies
          (commonly known as private limited companies). A private limited
          company in Bangladesh has a separate legal entity and shareholders are
          not liable for the company’s debts beyond the amount of share capital
          they have contributed. According to the Companies Act 1994, any person
          (foreign or local) can company formation or register a company in
          Bangladesh who is above 18 years old.
        </p>
      </div>
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <img src={tunelImg} alt="tunelImg" width={"100%"} />
              </div>
              <div class="col-md-6">
                <p style={{ lineHeight: "31px" }}>
                  Bangabandhu Sheikh Mujibur Rahman Tunnel (or Karnaphuli
                  Tunnel) is an underwater expressway tunnel in the port city of
                  Chittagong, Bangladesh under the Karnaphuli river. The length
                  of the entire route is 9.39 kilometres (5.83 mi), with the
                  tunnel making up 3.32 kilometres (2.06 mi) of the length. The
                  tunnel diameter will be 10.80 metres (35.4 ft). The cost of
                  the project is estimated at US$1.1B, of which around half is
                  financed by the Exim Bank of China. The tunnel is expected to
                  be completed in 2023 and will be the first under-water road
                  tunnel in South Asia. It is expected to improve the
                  Dhaka—Chittagong—Cox’s Bazar highway network. A Chinese
                  company, China Communications Construction Company, was
                  selected to construct it.
                </p>
              </div>
            </div>
          </div>
          <div class="container" style={{ marginTop: "50px" }}>
            <div class="row">
              <div class="col-md-6">
                <p style={{ lineHeight: "31px" }}>
                  The Bangladesh Export Processing Zones Authority (BEPZA) is an
                  agency of the Government of Bangladesh and is administered
                  under the jurisdiction of the Prime Minister’s Office. Its
                  objective is to manage the various export processing zones in
                  Bangladesh. BEPZA currently oversees the operations of eight
                  export processing zones (EPZ). A ninth zone is scheduled to
                  open in the future. Recently government has announced that in
                  15 years 100 new EPZ and SEZ will be established. Major
                  General Abul Kalam Mohammad Ziaur Rahman, ndc, psc is the
                  current Executive Chairman of BEPZA. The Government provides
                  numerous incentives for investors for opening factories in
                  EPZs. For example, new factories enjoy tax holidays for 5
                  years. Also, labour unions and other activities that are often
                  viewed detrimental to productivity, are banned inside the
                  EPZs.
                </p>
              </div>
              <div class="col-md-6">
                <img src={bepzaImg} alt="tunelImg" width={"100%"} />
              </div>
              <div class="row" style={{ marginTop: "50px" }}>
                <div class="col-md-6">
                  <img src={padmaBridgeImg} alt="tunelImg" width={"100%"} />
                </div>
                <div class="col-md-6">
                  <p style={{ lineHeight: "31px" }}>
                    The Padma Multipurpose Bridge commonly known as the Padma
                    Bridge, is a two-level road-rail bridge across the Padma
                    River, the main distributary of the Ganges in Bangladesh. It
                    connects Louhajang Upazila of Munshiganj and Zazira Upazila
                    of Shariatpur and a samall part of Shibchar Upazila of
                    Madaripur, linking the less developed southwest of the
                    country to the northern and eastern regions. The bridge was
                    inaugurated at moring on 25 June 2022 with white pigeon by
                    the Prime Minister Sheikh Hasina.
                  </p>
                </div>

                <div
                  class="col"
                  style={{
                    backgroundColor: "#2e5f71",
                    padding: "31px",
                    margin: "50px 10px",
                  }}
                >
                  <h1
                    style={{ marginBottom: "20px", color: "white" }}
                    className="display-6"
                  >
                  Company Registration in Bangladesh
                  </h1>
                  <p style={{ lineHeight: "31px", color: "white" }}>
                    The Company Formation or company registration relocation of
                    the existing company of Bangladesh and also note that most
                    of the Bangladeshi companies are registered as private
                    limited companies (commonly known as private limited
                    companies). A private limited company in Bangladesh has a
                    separate legal entity and shareholders are not liable for
                    the company’s debts beyond the amount of share capital they
                    have contributed. According to the Companies Act 1994, any
                    person (foreign or local) can company formation or register
                    a company in Bangladesh who is above 18 years old.
                  </p>
                </div>
              </div>
              <div>
              <div
                  class="col"
                  style={{
                    padding: "31px",
                    margin: "50px 10px",
                  }}
                >
                  <h1
                    style={{ marginBottom: "20px"}}
                    className="display-6"
                  >
                  How to register your business in the Bangladesh, in 4 simple steps
                  </h1>
                  <img src={stepOfBusinessRegister} alt="tunelImg" width={"100%"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
